<template>
  <div>
    <Navgation bgColor="#060B4C" />
    <div class="partner-container">
      <section class="romotional-video">
        <video src="/static/videos/partner.mp4" autoplay loop muted></video>
        <div class="video-modal"></div>
        <div class="title" v-show="videoShow">
          <img src="@/assets/images/partner/title.png" alt="" />
        </div>
        <div class="label" v-show="videoShow">十角兽全国合伙人招募</div>
        <div class="join-form-box" v-show="videoShow">
          <div class="join-form">
            <el-form ref="joinFormRef" :model="joinForm" label-width="0px">
              <el-form-item>
                <el-input
                  v-model.trim="joinForm.name"
                  placeholder="请输入您的姓名"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model.trim="joinForm.mobile"
                  placeholder="请输入您的手机号"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="apply-btn" @click="applyJoin">申请加盟</div>
          </div>
        </div>
        <div v-show="videoShow" class="tel">加盟热线：400-631-9898</div>
      </section>
      <section
        class="difficulty"
        :class="{
          'pre-hide': true,
          'move-bottomDifficulty': isShowDifficulty,
        }"
      >
        <div class="difficulty-content">
          <div class="title">您是否遇到以下瓶颈</div>
          <img src="@/assets/images/partner/difficulty.png" alt="" />
        </div>
      </section>
      <section
        class="reason"
        :class="{
          'pre-hide': true,
          'move-bottomReason': isShowReason,
        }"
      >
        <div class="reason-content">
          <div class="title">遇到瓶颈，为什么选择加盟十角兽</div>
          <div class="reason-list">
            <div
              class="reason-card"
              :class="{ 'active-reason-card': activeReason === index }"
              @mouseover="
                () => {
                  activeReason = index
                }
              "
              v-for="(item, index) in reasonList"
              :key="index"
            >
              <img :src="imgs[item.img]" alt="" />
              <div class="reason-text" v-html="item.content"></div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="service"
        :class="{
          'pre-hide': true,
          'move-bottomService': isShowService,
        }"
      >
        <div class="service-content">
          <div class="title">我们可以给您带来什么</div>
          <div class="label">强大的产品、贴心的服务，为您创造更多价值</div>
          <div class="tabs">
            <div
              class="tab"
              :class="{ 'active-tab': activeTab === index }"
              v-for="(item, index) in tabList"
              :key="index"
              @click="clickTab(index)"
            >
              {{ item.label }}
            </div>
          </div>
          <div class="tab-content" v-if="activeTab === 0">
            <div
              class="tab-content-info"
              :class="{
                'left-scale1': isShowReasonLeft,
              }"
            >
              <div
                class="tab-content-info-body"
                :class="{ 'animation-info1': activeTab === 0 }"
              >
                <div class="tab-content-title">
                  {{ tabList[activeTab].title }}
                </div>
                <div class="info-content">{{ tabList[activeTab].content }}</div>
                <div class="advantage-list">
                  <div
                    class="advantage"
                    v-for="(item, index) in tabList[activeTab].advantages"
                    :key="index"
                  >
                    <img src="@/assets/images/partner/success.png" alt="" />
                    {{ item }}
                  </div>
                </div>
                <img
                  class="tab-content-info-img"
                  src="@/assets/images/partner/product-bg.png"
                  alt=""
                />
              </div>
            </div>
            <img
              class="black-img"
              :class="{
                'left-scale2': isShowReasonLeft,
              }"
              src="@/assets/images/partner/product-bg2.png"
              alt=""
            />
          </div>
          <div class="tab-content" v-if="activeTab === 1">
            <img
              class="black-img"
              src="@/assets/images/partner/service-bg.png"
              alt=""
              :class="{
                'right-scale2': isShowReasonRight,
              }"
            />
            <div
              class="tab-content-info"
              :class="{
                'right-scale1': isShowReasonRight,
              }"
            >
              <div
                class="tab-content-info-body"
                :class="{ 'animation-info2': activeTab === 1 }"
              >
                <div class="tab-content-title">
                  {{ tabList[activeTab].title }}
                </div>
                <div class="info-content">{{ tabList[activeTab].content }}</div>
                <div class="advantage-list">
                  <div
                    class="advantage"
                    v-for="(item, index) in tabList[activeTab].advantages"
                    :key="index"
                  >
                    <img src="@/assets/images/partner/success.png" alt="" />
                    {{ item }}
                  </div>
                </div>
                <img
                  class="tab-content-info-img"
                  src="@/assets/images/partner/service-bg2.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="process">
        <div class="process-content">
          <div
            class="total-process"
            :class="{
              'pre-hide': true,
              'move-bottomProcess': isShowProcess,
            }"
          >
            <div class="title">三步流程 轻松成为合伙人</div>
            <div class="content">
              <div
                class="process-item"
                v-for="(item, index) in totalProcessList"
                :key="'total' + index"
              >
                <div class="item-box">
                  <img class="item-img" :src="processImgs[item.img]" alt="" />
                  <div class="item-title">{{ item.title }}</div>
                  <div class="item-content">{{ item.content }}</div>
                </div>
                <img
                  v-if="index !== totalProcessList.length - 1"
                  class="right-arrow"
                  src="@/assets/images/partner/right-arrow.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div
            class="threshold"
            :class="{
              'pre-hide': true,
              'move-bottomThreshold': isShowThreshold,
            }"
          >
            <div class="title">0门槛成为十角兽合伙人</div>
            <div class="content">
              <img
                class="threshold-img"
                src="@/assets/images/partner/threshold-img.png"
                alt=""
              />
              <div class="text-box">
                <div class="text-title">加盟支持</div>
                <div class="text-content">
                  即享十角兽全方位产品与服务支持，有效解决您企业服务产品差异化不足的问题，拓宽您的获客渠道，提升客户黏性，提高客户复购率，打造全新数字化工商财税+传统企业服务新生态
                </div>
              </div>
            </div>
          </div>
          <div
            class="need-you"
            :class="{
              'pre-hide': true,
              'move-bottomNeed': isShowNeed,
            }"
          >
            <div class="title">没错，我们的合伙人就是你</div>
            <div class="content">
              <div class="card-list">
                <div
                  class="card-item"
                  v-for="(item, index) in needCardList"
                  :key="'need' + index"
                  @mouseover="clickNeedCard(index)"
                >
                  <img
                    class="card-img"
                    :class="{
                      'active-card-img': activeNeedCard === index,
                    }"
                    :src="
                      activeNeedCard === index
                        ? needImgs[item.activeImg]
                        : needImgs[item.img]
                    "
                    alt=""
                  />
                  <div class="card-content" v-if="activeNeedCard !== index">
                    <div class="card-title">{{ item.title }}</div>
                  </div>
                  <div class="active-card-content" v-else>
                    <div class="card-title">{{ item.title }}</div>
                    <div class="card-text">{{ item.content }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="interests"
            :class="{
              'pre-hide': true,
              'move-bottomInterests': isShowInterests,
            }"
          >
            <div class="title">合伙人权益</div>
            <div class="content">
              <div class="interests-list">
                <div
                  class="interests-item"
                  v-for="(item, index) in interestsCardList"
                  :key="'interests' + index"
                >
                  <div v-if="item.type === 'equity'" class="equity-box">
                    <div class="equity-box-top">{{ item.title }}</div>
                    <div class="equity-box-bottom">
                      <div
                        v-for="(childItem, childIndex) in item.children"
                        :key="'equity' + childIndex"
                      >
                        {{ childItem }}
                      </div>
                    </div>
                  </div>
                  <div v-if="item.type === 'profit'" class="profit-box">
                    <div class="profit-box-top">{{ item.title }}</div>
                    <div class="profit-box-bottom">
                      <div
                        v-for="(equityItem, equityIndex) in item.children"
                        :key="'profit' + equityIndex"
                      >
                        {{ equityItem }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="other-box"
                    v-if="
                      ['threshold', 'time', 'proportion'].includes(item.type)
                    "
                  >
                    <div class="other-box-top">{{ item.title }}</div>
                    <div class="other-box-bottom">{{ item.content }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="adsorption-box"
        v-show="adsorptionIsShow"
        :class="{
          'top-hide': !isShowAdsorptionHide,
          'move-top': isShowAdsorption,
        }"
      >
        <div class="adsorption-form">
          <el-form ref="adsorptionFormRef" :model="joinForm" label-width="0px">
            <el-form-item>
              <el-input
                v-model.trim="joinForm.name"
                placeholder="请输入您的姓名"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model.trim="joinForm.mobile"
                placeholder="请输入您的手机号"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="adsorption-btn" @click="applyJoin">申请加盟</div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import Navgation from '@/layouts/components/Navgation'
import partnerApi from '@/api/partner.api.js'

export default {
  name: 'partner',
  components: { Navgation },
  inject: ['getPosition', 'scrollTo'],
  data() {
    return {
      joinForm: {
        name: '',
        mobile: '',
      },
      imgs: {
        reason1: require('@/assets/images/partner/reason1.png'),
        reason2: require('@/assets/images/partner/reason2.png'),
        reason3: require('@/assets/images/partner/reason3.png'),
        reason4: require('@/assets/images/partner/reason4.png'),
      },
      reasonList: [
        {
          img: 'reason1',
          content: '<p>互联网企业工商财税</p><p>一站式服务平台</p>',
        },
        {
          img: 'reason2',
          content: '<p>中国大陆区域</p><p>所有省市工商税务皆可服务</p>',
        },
        {
          img: 'reason3',
          content: '<p>近百家银行、企业服务机构</p><p>达成合作</p>',
        },
        {
          img: 'reason4',
          content: '<p>帮助企业获客、锁客</p><p>数百万家</p>',
        },
      ],
      tabList: [
        {
          label: '超强产品',
          title: '我们的产品',
          content:
            '帮助您解决企业服务产品缺乏差异化的痛点，提供强有力的获客抓手，降低获客成本，提高用户活跃度和产品复购率，为您创造更多价值',
          advantages: ['聚焦增量市场', '刚需且高频', '轻量化、移动端'],
        },
        {
          label: '超值服务',
          title: '我们的服务',
          content:
            '0门槛即可成为十角兽合伙人，签约之日起享受总部全方位的产品与技术服务支持，售前售后均由总部负责跟进，提供管家级贴心服务',
          advantages: ['0成本 轻交付', '分享即分润', '售前售后不操心'],
        },
      ],
      activeTab: 0,
      activeReason: 0,
      processImgs: {
        processIcon1: require('@/assets/images/partner/process-icon1.png'),
        processIcon2: require('@/assets/images/partner/process-icon2.png'),
        processIcon3: require('@/assets/images/partner/process-icon3.png'),
      },
      totalProcessList: [
        {
          title: '0门槛申请',
          content: '无需加盟费用，产品0交付轻松使用',
          img: 'processIcon1',
        },
        {
          title: '客服回访',
          content: '专业客服微信/电话回访，推进加盟事宜',
          img: 'processIcon2',
        },
        {
          title: '线上签约',
          content: '电子合同，线上即可完成签约，成为合伙人',
          img: 'processIcon3',
        },
      ],
      activeNeedCard: 0,
      needImgs: {
        competitivenessBg: require('@/assets/images/partner/competitiveness-bg.png'),
        competitivenessBg2: require('@/assets/images/partner/competitiveness-bg2.png'),
        dimensionBg: require('@/assets/images/partner/dimension-bg.png'),
        dimensionBg2: require('@/assets/images/partner/dimension-bg2.png'),
        industryBg: require('@/assets/images/partner/industry-bg.png'),
        industryBg2: require('@/assets/images/partner/industry-bg2.png'),
        miningBg: require('@/assets/images/partner/mining-bg.png'),
        miningBg2: require('@/assets/images/partner/mining-bg2.png'),
        conceptBg: require('@/assets/images/partner/concept-bg.png'),
        conceptBg2: require('@/assets/images/partner/concept-bg2.png'),
      },
      needCardList: [
        {
          title: '共同理念',
          content:
            '认可十角兽产品和发展理念，愿意和公司一起成长，共同为中国小微企业提供高质量服务',
          activeImg: 'conceptBg',
          img: 'conceptBg2',
        },
        {
          title: '企业服务',
          content:
            '正在从事企业服务相关业务，拥有一定的企业用户资源，和开发企业用户的渠道和能力',
          activeImg: 'industryBg',
          img: 'industryBg2',
        },
        {
          title: '竞争力',
          content:
            '渴望提升目前业务的差异化竞争力，快速打开市场，提高市场占有率和加快公司发展速度',
          activeImg: 'competitivenessBg',
          img: 'competitivenessBg2',
        },
        {
          title: '业务维度',
          content:
            '渴望提升目前业务的差异化竞争力，快速打开市场，提高市场占有率和加快公司发展速度',
          activeImg: 'dimensionBg',
          img: 'dimensionBg2',
        },
        {
          title: '用户挖掘',
          content:
            '渴望深度挖掘用户价值，提升用户的复购率和客单价，与用户建立坚固的合作关系',
          activeImg: 'miningBg',
          img: 'miningBg2',
        },
      ],
      interestsCardList: [
        {
          title: '合伙人门槛',
          content: '框架协议',
          type: 'threshold',
        },
        {
          title: '产品使用时限',
          content: '合同期内',
          type: 'time',
        },
        {
          title: '代理商权益',
          type: 'equity',
          children: [
            '①0门槛0费用',
            '②送营销拓客系统',
            '③客户新签、续费分润30%',
          ],
        },
        {
          title: '业务分润',
          type: 'profit',
          children: ['新客', '续费'],
        },
        {
          title: '合伙人/直客',
          content: '30%',
          type: 'proportion',
        },
      ],
      adsorptionIsShow: false,
      isShowDifficulty: false,
      isShowReason: false,
      isShowService: false,
      isShowProcess: false,
      isShowThreshold: false,
      isShowNeed: false,
      isShowInterests: false,
      isShowReasonLeft: false,
      isShowReasonRight: false,
      isShowAdsorption: false,
      isShowAdsorptionHide: false,
      oldScroll: 0,
      newScroll: 0,
      scrollTimer: null,
      joinTimer: null,
      videoShow: false,
    }
  },
  mounted() {
    let videoTimer = null
    videoTimer = setTimeout(() => {
      this.videoShow = true
      clearTimeout(videoTimer)
      videoTimer = false
    }, 200)
    const { scrollTop } = this.getPosition()
    const clientHeight = document.documentElement.clientHeight
    if (clientHeight > 1150) {
      if (scrollTop >= 0) {
        this.isShowDifficulty = true
      }
    }

    window.addEventListener('scroll', this.onScroll, true)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll, true)
  },
  methods: {
    clickTab(val) {
      this.activeTab = val
      if (val === 0) {
        this.isShowReasonLeft = true
        this.isShowReasonRight = false
      } else {
        this.isShowReasonLeft = false
        this.isShowReasonRight = true
      }
    },
    clickNeedCard(val) {
      this.activeNeedCard = val
    },
    handleScroll(scrollTop) {
      if (!this.adsorptionIsShow) {
        this.adsorptionIsShow = true
      }
      this.newScroll = scrollTop
      if (this.newScroll == this.oldScroll) {
        this.isShowAdsorptionHide = true
        this.isShowAdsorption = true
        clearTimeout(this.scrollTimer)
        this.scrollTimer = null
        this.oldScroll = 0
        this.newScroll = 0
      }
    },
    onScroll() {
      const { scrollTop } = this.getPosition()
      const clientHeight = document.documentElement.clientHeight

      if (clientHeight <= 1150) {
        if (scrollTop >= 4850) {
          this.isShowInterests = true
        }
        if (scrollTop >= 4150) {
          this.isShowNeed = true
        }
        if (scrollTop >= 3400) {
          this.isShowThreshold = true
        }
        if (scrollTop >= 2800) {
          this.isShowProcess = true
        }
        if (scrollTop >= 1950) {
          this.isShowService = true
          this.isShowReasonLeft = true
        }
        if (scrollTop >= 1100) {
          this.isShowReason = true
        }
        if (scrollTop >= 200) {
          this.isShowDifficulty = true
        }
      } else {
        if (scrollTop >= 4600) {
          this.isShowInterests = true
        }
        if (scrollTop >= 3950) {
          this.isShowNeed = true
        }
        if (scrollTop >= 3150) {
          this.isShowThreshold = true
        }
        if (scrollTop >= 2550) {
          this.isShowProcess = true
        }
        if (scrollTop >= 1750) {
          this.isShowService = true
          this.isShowReasonLeft = true
        }
        if (scrollTop >= 900) {
          this.isShowReason = true
        }
      }

      //吸附代码
      if (scrollTop >= 700) {
        this.isShowAdsorptionHide = false
        this.isShowAdsorption = false
        if (this.scrollTimer) {
          clearTimeout(this.scrollTimer)
          this.scrollTimer = null
          this.oldScroll = scrollTop
          this.scrollTimer = setTimeout(() => {
            this.handleScroll(scrollTop)
          }, 700)
        } else {
          this.oldScroll = scrollTop
          this.scrollTimer = setTimeout(() => {
            this.handleScroll(scrollTop)
          }, 700)
        }
      } else {
        this.oldScroll = 0
        this.newScroll = 0
        this.isShowAdsorptionHide = false
        this.isShowAdsorption = false
      }
    },
    applyJoin() {
      if (this.joinForm.name && this.joinForm.mobile) {
        if (this.joinTimer) {
          clearTimeout(this.joinTimer)
          this.joinTimer = null
        }
        this.joinTimer = setTimeout(() => {
          partnerApi
            .sendJoinMessage(this.joinForm)
            .then((res) => {
              this.$message.success('加盟申请提交成功')
              this.joinForm.mobile = ''
              this.joinForm.name = ''
            })
            .catch((err) => {
              this.$message.error('加盟申请提交失败')
            })
            .finally(() => {
              clearTimeout(this.joinTimer)
              this.joinTimer = null
            })
        }, 1000)
      }
    },
  },
}
</script>
<style lang="less" scoped>
.partner-container {
  margin-top: @navHeight;
  .romotional-video {
    width: 100%;
    background: #01031b;
    height: 724px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    video {
      width: 100%;
      height: 800px;
    }
    .video-modal {
      width: 100%;
      height: 100%;
      background: linear-gradient(
        270deg,
        #040c50 0%,
        #0e1a82 50%,
        #040c50 100%
      );
      opacity: 0.75;
      position: absolute;
      top: 0;
    }
    .title {
      width: 100%;
      position: absolute;
      z-index: 2;
      top: 100px;
      // left: calc(50% - 470px);
      left: 0px;
      display: flex;
      justify-content: center;
      img {
        width: 940px;
        height: 147px;
      }
    }
    .label {
      position: absolute;
      z-index: 2;
      top: 250px;
      // left: calc(50% - 140px);
      left: 0px;
      width: 100%;
      text-align: center;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
    .join-form-box {
      position: absolute;
      z-index: 2;
      top: 328px;
      // left: calc(50% - 186px);
      left: 0px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .join-form {
        width: 372px;
        height: 278px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 17px;
        backdrop-filter: blur(25px);
        padding: 34px 44px;
        box-sizing: border-box;
        .el-form {
          /deep/.el-form-item {
            margin-bottom: 0px;
            .el-form-item__content {
              .el-input {
                .el-input__inner {
                  height: 50px;
                }
              }
            }
          }
          .el-form-item + .el-form-item {
            margin-top: 24px;
          }
        }
        .apply-btn {
          margin: 36px auto 0px;
          width: 160px;
          height: 50px;
          background: #2871ff;
          border-radius: 9px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
    .tel {
      position: absolute;
      z-index: 2;
      bottom: 40px;
      // left: calc(50% - 105px);
      left: 0px;
      width: 100%;
      text-align: center;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .difficulty {
    height: 905px;
    background: linear-gradient(180deg, #ffffff 0%, #f6f9fe 100%);
    .difficulty-content {
      width: @panelWidth;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        margin-top: 70px;
        text-align: center;
        font-size: 48px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #102a55;
        line-height: 67px;
      }
      img {
        margin-top: 75px;
        width: 1010px;
        height: 672px;
      }
    }
  }
  .reason {
    height: 845px;
    .reason-content {
      width: @panelWidth;
      height: 100%;
      margin: 0 auto;
      padding-top: 126px;
      box-sizing: border-box;
      .title {
        font-size: 48px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #102a55;
        text-align: center;
        line-height: 67px;
      }
      .reason-list {
        margin-top: 66px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .reason-card {
          width: 502px;
          height: 210px;
          background: #ffffff;
          // box-shadow: 0px 0px 138px 0px rgba(171, 190, 214, 0.2);
          display: flex;
          align-items: center;
          padding: 37px 30px;
          box-sizing: border-box;
          img {
            width: 136px;
            height: 136px;
            margin-right: 20px;
          }
          .reason-text {
            font-size: 22px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #102a55;
            line-height: 30px;
          }
        }
        .active-reason-card {
          box-shadow: 0px 0px 40px 0px rgba(171, 190, 214, 0.2);
        }
        .reason-card:nth-of-type(odd) {
          margin-right: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .service {
    height: 800px;
    background: #060b4c;
    .service-content {
      width: @panelWidth;
      height: 100%;
      margin: 0 auto;
      padding-top: 90px;
      box-sizing: border-box;
      .title {
        font-size: 48px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
        line-height: 67px;
      }
      .label {
        margin-top: 20px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
      }
      .tabs {
        margin-top: 66px;
        display: flex;
        align-items: center;
        justify-content: center;
        .tab {
          width: 136px;
          height: 43px;
          text-align: center;
          line-height: 43px;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.8);
          cursor: pointer;
        }
        .tab + .tab {
          margin-left: 30px;
        }
        .active-tab {
          background: #ffffff;
          border-radius: 22px;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #0056fd;
        }
      }
      .tab-content {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        .tab-content-info {
          width: 632px;
          height: 294px;
          background-color: #fff;
          position: relative;
          z-index: 1;
          overflow: hidden;
          .tab-content-info-body {
            padding: 58px 40px;
            box-sizing: border-box;

            .tab-content-title {
              font-size: 24px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #102a55;
              position: relative;
              z-index: 3;
            }
            .info-content {
              margin: 14px 0px 20px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(16, 42, 85, 0.6);
              line-height: 22px;
              position: relative;
              z-index: 3;
            }
            .advantage-list {
              width: 60%;
              display: flex;
              flex-wrap: wrap;
              position: relative;
              z-index: 3;

              .advantage {
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #102a55;
                display: flex;
                align-items: center;
                margin-bottom: 14px;
                img {
                  width: 16px;
                  height: 12px;
                  margin-right: 8px;
                }
              }
              .advantage:nth-of-type(1) {
                margin-right: 72px;
              }
            }
            .tab-content-info-img {
              position: absolute;
              top: 0px;
              left: 0px;
              z-index: 2;
              width: 632px;
              height: 294px;
            }
          }
        }
        .animation-info2 {
          opacity: 0;
          animation: tab-content-open 0.8s 0.2s forwards ease-in-out;
        }
        .animation-info1 {
          opacity: 0;
          animation: tab-content-open1 0.8s 0.2s forwards ease-in-out;
        }
        .black-img {
          width: 632px;
          height: 294px;
          // width: 390px;
          // height: 260px;
        }
      }
    }
  }
  .process {
    .process-content {
      width: @panelWidth;
      height: 100%;
      margin: 0 auto;
      padding: 160px 0px;
      box-sizing: border-box;
      .title {
        font-size: 48px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #102a55;
        line-height: 67px;
      }
      .total-process {
        display: flex;
        flex-direction: column;
        align-items: center;
        .content {
          width: 1024px;
          height: 260px;
          margin-top: 80px;
          background: #ffffff;
          box-shadow: 0px 0px 138px 0px rgba(171, 190, 214, 0.2);
          display: flex;
          align-items: center;
          justify-content: space-around;
          padding: 0px 60px;
          box-sizing: border-box;
          .process-item {
            display: flex;
            justify-content: center;
            .item-box {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 210px;
              .item-img {
                width: 98px;
                height: 98px;
              }
              .item-title {
                font-size: 24px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #102a55;
                line-height: 33px;
              }
              .item-content {
                margin-top: 6px;
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #102a55;
                line-height: 21px;
                opacity: 0.5;
                text-align: center;
              }
            }
            .right-arrow {
              width: 50px;
              height: 260px;
              margin-left: 28px;
            }
          }
        }
      }
      .threshold {
        margin-top: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .content {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 80px;
          padding-left: 168px;
          box-sizing: border-box;
          .threshold-img {
            width: 512px;
            height: 440px;
          }
          .text-box {
            width: 648px;
            height: 378px;
            background: #ffffff;
            box-shadow: 0px 0px 138px 0px rgba(171, 190, 214, 0.2);
            position: relative;
            left: -168px;
            padding: 0px 46px 0px 58px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .text-title {
              font-size: 32px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #102a55;
              line-height: 45px;
            }
            .text-content {
              margin-top: 30px;
              font-size: 18px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(16, 42, 85, 0.6);
              line-height: 25px;
            }
          }
        }
      }
      .need-you {
        margin-top: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .content {
          margin-top: 80px;
          .card-list {
            width: 1022px;
            display: flex;
            position: relative;
            .card-img {
              width: 142px;
              height: 380px;
              transition: all 0.5s linear;
              -webkit-transition: all 0.5s linear;
            }
            // .hide-card-img {
            // animation: need-card-one-hide 0.3s 0s forwards ease-in-out;
            // }
            .active-card-img {
              width: 390px;
              transition: all 0.5s linear;
              -webkit-transition: all 0.5s linear;
              // animation: need-card-one-open 0.8s 0.1s forwards ease-in-out;
            }
            .card-item {
              position: relative;
              height: 380px;
              .card-content {
                width: 100%;
                position: absolute;
                top: 0px;
                left: 0px;
                .card-title {
                  font-size: 24px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #2871ff;
                  line-height: 33px;
                  text-align: center;
                  margin-top: 64px;
                }
              }
              .active-card-content {
                position: absolute;
                top: 0px;
                left: 0px;
                padding: 64px 45px 0px 23px;
                box-sizing: border-box;
                .card-title {
                  font-size: 24px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #ffffff;
                  line-height: 33px;
                  opacity: 0;
                  animation: opacity-frames 0.1s 0s forwards ease-in-out;
                }
                .card-title::after {
                  content: '';
                  display: block;
                  width: 36px;
                  height: 4px;
                  opacity: 0;
                  background: #ffffff;
                  border-radius: 3px;
                  margin-top: 16px;
                  animation: need-card-line-open 0.5s 0.1s forwards ease-in-out;
                }
                .card-text {
                  margin-top: 38px;
                  font-size: 20px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #ffffff;
                  line-height: 28px;
                  opacity: 0;
                  animation: need-card-text-open 0.7s 0.4s forwards ease-in-out;
                }
              }
            }
            .card-item + .card-item {
              margin-left: 16px;
            }
          }
        }
      }
      .interests {
        margin-top: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .content {
          margin-top: 80px;
          box-shadow: 0px 0px 138px 0px rgba(171, 190, 214, 0.2);
          .interests-list {
            display: flex;
            .interests-item {
              .other-box {
                font-size: 18px;
                text-align: center;
                .other-box-top {
                  width: 168px;
                  height: 120px;
                  background: #2871ff;
                  // box-shadow: 0px 0px 138px 0px rgba(171, 190, 214, 0.2);
                  border-right: 1px solid #ffffff;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #ffffff;
                  text-shadow: 0px 0px 138px rgba(171, 190, 214, 0.2);
                  line-height: 120px;
                }
                .other-box-bottom {
                  width: 168px;
                  height: 168px;
                  background: #ffffff;
                  // box-shadow: 0px 0px 138px 0px rgba(171, 190, 214, 0.2);
                  border-right: 1px solid #eef0f3;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #102a55;
                  text-shadow: 0px 0px 138px rgba(171, 190, 214, 0.2);
                  line-height: 168px;
                }
              }
              .equity-box {
                font-size: 18px;
                .equity-box-top {
                  width: 284px;
                  height: 70px;
                  background: #2871ff;
                  // box-shadow: 0px 0px 138px 0px rgba(171, 190, 214, 0.2);
                  border-right: 1px solid #ffffff;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #ffffff;
                  text-shadow: 0px 0px 138px rgba(171, 190, 214, 0.2);
                  padding: 50px 0px 0px 62px;
                }
                .equity-box-bottom {
                  width: 284px;
                  height: 168px;
                  background: #ffffff;
                  // box-shadow: 0px 0px 138px 0px rgba(171, 190, 214, 0.2);
                  border-right: 1px solid #eef0f3;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  padding-left: 62px;
                  div {
                    line-height: 36px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #102a55;
                    text-shadow: 0px 0px 138px rgba(171, 190, 214, 0.2);
                  }
                }
              }
              .profit-box {
                font-size: 18px;
                text-align: center;
                .profit-box-top {
                  width: 168px;
                  height: 120px;
                  background: #2871ff;
                  // box-shadow: 0px 0px 138px 0px rgba(171, 190, 214, 0.2);
                  border-right: 1px solid #ffffff;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #ffffff;
                  text-shadow: 0px 0px 138px rgba(171, 190, 214, 0.2);
                  line-height: 120px;
                }
                .profit-box-bottom {
                  width: 168px;
                  height: 168px;
                  background: #ffffff;
                  // box-shadow: 0px 0px 138px 0px rgba(171, 190, 214, 0.2);
                  border-right: 1px solid #eef0f3;
                  div {
                    height: 50%;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #102a55;
                    text-shadow: 0px 0px 138px rgba(171, 190, 214, 0.2);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                  div:nth-of-type(1) {
                    border-bottom: 1px solid #eef0f3;
                    box-sizing: border-box;
                  }
                }
              }
            }
            .interests-item:nth-last-of-type(1) {
              .other-box {
                .equity-box-top {
                  border-right: 0px solid #fff;
                }
                .equity-box-bottom {
                  border-right: 0px solid #fff;
                }
              }
            }
          }
        }
      }
    }
  }
  .adsorption-box {
    position: fixed;
    top: 60px;
    z-index: 9;
    width: 100%;
    height: 96px;
    background: rgba(6, 11, 76, 0.5);
    display: flex;
    align-items: center;
    .adsorption-form {
      margin: auto;
      width: 664px;
      height: 58px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 14px;
      backdrop-filter: blur(20px);
      padding: 12px;
      box-sizing: border-box;
      display: flex;
      .el-form {
        display: flex;
        /deep/.el-form-item {
          margin-bottom: 0px;
          .el-form-item__content {
            line-height: 34px;
            .el-input {
              .el-input__inner {
                width: 228px;
                height: 34px;
              }
            }
          }
        }
        .el-form-item + .el-form-item {
          margin-left: 10px;
        }
      }
      .adsorption-btn {
        width: 162px;
        height: 34px;
        background: #2871ff;
        border-radius: 7px;
        font-size: 13px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 34px;
        text-align: center;
        margin-left: 13px;
        cursor: pointer;
      }
    }
  }
  .left-scale1 {
    animation: left-scale1 0.8s 0s forwards ease-in-out;
  }
  .left-scale2 {
    animation: left-scale2 0.6s 0s forwards ease-in-out;
  }
  .right-scale1 {
    animation: right-scale1 0.8s 0s forwards ease-in-out;
  }
  .right-scale2 {
    animation: right-scale2 0.6s 0s forwards ease-in-out;
  }
  .pre-hide {
    transform: translateY(100px);
    opacity: 0;
  }
  .move-bottomDifficulty {
    animation: move-bottom 1s 0.2s forwards ease-in-out;
  }
  .move-bottomReason {
    animation: move-bottom 1s 0.2s forwards ease-in-out;
  }
  .move-bottomService {
    animation: move-bottom 1s 0.2s forwards ease-in-out;
  }
  .move-bottomProcess {
    animation: move-bottom 1s 0.2s forwards ease-in-out;
  }
  .move-bottomThreshold {
    animation: move-bottom 1s 0.2s forwards ease-in-out;
  }
  .move-bottomNeed {
    animation: move-bottom 1s 0.2s forwards ease-in-out;
  }
  .move-bottomInterests {
    animation: move-bottom 1s 0.2s forwards ease-in-out;
  }
  .left-hide {
    transform: translateX(-150px);
    opacity: 0;
  }
  .right-hide {
    transform: translateX(150px);
    opacity: 0;
  }
  .move-left1 {
    animation: move-left 0.5s 0s forwards ease-in-out;
  }
  .move-right1 {
    animation: move-right 0.5s 0s forwards ease-in-out;
  }
  .move-top {
    animation: option-top 0.5s forwards ease-in-out;
  }
  .top-hide {
    animation: option-top-hide 0.5s forwards ease-in-out;
  }
}
@keyframes move-bottom {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes move-left {
  0% {
    transform: translateX(-150px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes move-right {
  0% {
    transform: translateX(150px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes option-top {
  0% {
    transform: translateY(-300px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes option-top-hide {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-300px);
  }
}
@keyframes need-card-one-open {
  0% {
    width: 142px;
  }
  100% {
    width: 390px;
  }
}
@keyframes need-card-one-hide {
  0% {
    width: 390px;
  }
  100% {
    width: 142px;
  }
}
@keyframes need-card-line-open {
  0% {
    transform: translateX(-36px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes need-card-text-open {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes opacity-frames {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tab-content-open {
  0% {
    transform: translateY(300px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes tab-content-open1 {
  0% {
    transform: translateY(300px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes left-scale1 {
  0% {
    // transform: scale(0.8);
    width: 390px;
    height: 260px;
    opacity: 0;
  }
  100% {
    // transform: scale(1);
    width: 632px;
    height: 294px;
    opacity: 1;
  }
}
@keyframes left-scale2 {
  0% {
    // transform: scale(1.4);
    width: 632px;
    height: 294px;
    opacity: 0;
  }
  100% {
    // transform: scale(1);
    width: 390px;
    height: 260px;
    opacity: 1;
  }
}
@keyframes right-scale1 {
  0% {
    // transform: scale(0.8);
    width: 390px;
    height: 260px;
    opacity: 0;
  }
  100% {
    // transform: scale(1);
    width: 632px;
    height: 294px;
    opacity: 1;
  }
}
@keyframes right-scale2 {
  0% {
    // transform: scale(1.4);
    width: 632px;
    height: 294px;
    opacity: 0;
  }
  100% {
    // transform: scale(1);
    width: 390px;
    height: 260px;
    opacity: 1;
  }
}
</style>

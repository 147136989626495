<template>
  <div>
    <Navgation :isPageHome="false"  />
    <div class="container">
      <Jumbotron :url="isMobile ? 'about-m-banner' : 'about-us'" />
      <div class="about-us">
        <div class="title">公司简介</div>
        <div class="empty-line"></div>
        <div class="main-text">
          <p>海南十角兽网络科技有限公司是一家运用移动互联网、机器人流程自动化、人工智能、大数据等最新技术，结合资深财税行业经验，为企业提供便捷、高效、诚挚服务的工商财税数字化服务平台。公司致力于为全国几千万小微企业和亿级个体工商户，提供精准化、场景化、数字化的工商财税服务新体验的。</p>
          <div class="empty-line"></div>
          <p>
          公司成立于2021年，总部位于广州，在浙江、上海、山东、江苏等地设有分部，业务范围已经覆盖全国36个税区，已经为超过10万家企业用户提供专业优质的工商财税解决方案。未来，十角兽将基于行业领先的技术研发能力和体系化标准化的客户服务能力，帮助更多的小微企业以更低的成本享受更优质的服务，共同进入工商财税服务数字化新时代。
          </p>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkIsMobile from '@/mixin/checkIsMobile'
import { Jumbotron } from '@/base'
import Navgation from '@/layouts/components/Navgation'
export default {
  name: 'About',
  mixins: [checkIsMobile],
  components: {
    Jumbotron,
    Navgation
  },
}
</script>

<style scoped lang="less">
.container {
    margin-top: @navHeight;
}
.about-us {
  width: @panelWidth;
  margin: 70px auto;
  .empty-line {
    margin: 15px 0;
    overflow: hidden;
  }
  .title {
    font-size: 18px;
    font-family: AlibabaPuHuiTiM;
    color: #333333;
    line-height: 20px;
  }
  .main-text {
    line-height: 20px;
    letter-spacing: 1px;
    font-size: 14px;
    font-family: AlibabaPuHuiTiR;
    color: #717a85;
    line-height: 21px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    margin-top: 0;
}
  .about-us {
    width: 100%;
    .empty-line {
      margin: 20px 0;
      overflow: hidden;
    }
    .title {
      text-align: center;
      font-size: 36px;
      font-family: AlibabaPuHuiTiR;
      color: #000000;
      line-height: 50px;
      padding-top: 44px;
      padding-bottom: 48px;
    }
    .main-text {
      padding: 0 40px;
      font-size: 24px;
      font-family: AlibabaPuHuiTiR;
      color: #717a85;
      line-height: 48px;
    }
  }
}
</style>

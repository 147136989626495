var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Navgation',{attrs:{"bgColor":"#060B4C"}}),_c('div',{staticClass:"partner-container"},[_c('section',{staticClass:"romotional-video"},[_c('video',{attrs:{"src":"/static/videos/partner.mp4","autoplay":"","loop":"","muted":""},domProps:{"muted":true}}),_c('div',{staticClass:"video-modal"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.videoShow),expression:"videoShow"}],staticClass:"title"},[_c('img',{attrs:{"src":require("@/assets/images/partner/title.png"),"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.videoShow),expression:"videoShow"}],staticClass:"label"},[_vm._v("十角兽全国合伙人招募")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.videoShow),expression:"videoShow"}],staticClass:"join-form-box"},[_c('div',{staticClass:"join-form"},[_c('el-form',{ref:"joinFormRef",attrs:{"model":_vm.joinForm,"label-width":"0px"}},[_c('el-form-item',[_c('el-input',{attrs:{"placeholder":"请输入您的姓名"},model:{value:(_vm.joinForm.name),callback:function ($$v) {_vm.$set(_vm.joinForm, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"joinForm.name"}})],1),_c('el-form-item',[_c('el-input',{attrs:{"placeholder":"请输入您的手机号"},model:{value:(_vm.joinForm.mobile),callback:function ($$v) {_vm.$set(_vm.joinForm, "mobile", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"joinForm.mobile"}})],1)],1),_c('div',{staticClass:"apply-btn",on:{"click":_vm.applyJoin}},[_vm._v("申请加盟")])],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.videoShow),expression:"videoShow"}],staticClass:"tel"},[_vm._v("加盟热线：400-631-9898")])]),_c('section',{staticClass:"difficulty",class:{
        'pre-hide': true,
        'move-bottomDifficulty': _vm.isShowDifficulty,
      }},[_vm._m(0)]),_c('section',{staticClass:"reason",class:{
        'pre-hide': true,
        'move-bottomReason': _vm.isShowReason,
      }},[_c('div',{staticClass:"reason-content"},[_c('div',{staticClass:"title"},[_vm._v("遇到瓶颈，为什么选择加盟十角兽")]),_c('div',{staticClass:"reason-list"},_vm._l((_vm.reasonList),function(item,index){return _c('div',{key:index,staticClass:"reason-card",class:{ 'active-reason-card': _vm.activeReason === index },on:{"mouseover":() => {
                _vm.activeReason = index
              }}},[_c('img',{attrs:{"src":_vm.imgs[item.img],"alt":""}}),_c('div',{staticClass:"reason-text",domProps:{"innerHTML":_vm._s(item.content)}})])}),0)])]),_c('section',{staticClass:"service",class:{
        'pre-hide': true,
        'move-bottomService': _vm.isShowService,
      }},[_c('div',{staticClass:"service-content"},[_c('div',{staticClass:"title"},[_vm._v("我们可以给您带来什么")]),_c('div',{staticClass:"label"},[_vm._v("强大的产品、贴心的服务，为您创造更多价值")]),_c('div',{staticClass:"tabs"},_vm._l((_vm.tabList),function(item,index){return _c('div',{key:index,staticClass:"tab",class:{ 'active-tab': _vm.activeTab === index },on:{"click":function($event){return _vm.clickTab(index)}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),0),(_vm.activeTab === 0)?_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"tab-content-info",class:{
              'left-scale1': _vm.isShowReasonLeft,
            }},[_c('div',{staticClass:"tab-content-info-body",class:{ 'animation-info1': _vm.activeTab === 0 }},[_c('div',{staticClass:"tab-content-title"},[_vm._v(" "+_vm._s(_vm.tabList[_vm.activeTab].title)+" ")]),_c('div',{staticClass:"info-content"},[_vm._v(_vm._s(_vm.tabList[_vm.activeTab].content))]),_c('div',{staticClass:"advantage-list"},_vm._l((_vm.tabList[_vm.activeTab].advantages),function(item,index){return _c('div',{key:index,staticClass:"advantage"},[_c('img',{attrs:{"src":require("@/assets/images/partner/success.png"),"alt":""}}),_vm._v(" "+_vm._s(item)+" ")])}),0),_c('img',{staticClass:"tab-content-info-img",attrs:{"src":require("@/assets/images/partner/product-bg.png"),"alt":""}})])]),_c('img',{staticClass:"black-img",class:{
              'left-scale2': _vm.isShowReasonLeft,
            },attrs:{"src":require("@/assets/images/partner/product-bg2.png"),"alt":""}})]):_vm._e(),(_vm.activeTab === 1)?_c('div',{staticClass:"tab-content"},[_c('img',{staticClass:"black-img",class:{
              'right-scale2': _vm.isShowReasonRight,
            },attrs:{"src":require("@/assets/images/partner/service-bg.png"),"alt":""}}),_c('div',{staticClass:"tab-content-info",class:{
              'right-scale1': _vm.isShowReasonRight,
            }},[_c('div',{staticClass:"tab-content-info-body",class:{ 'animation-info2': _vm.activeTab === 1 }},[_c('div',{staticClass:"tab-content-title"},[_vm._v(" "+_vm._s(_vm.tabList[_vm.activeTab].title)+" ")]),_c('div',{staticClass:"info-content"},[_vm._v(_vm._s(_vm.tabList[_vm.activeTab].content))]),_c('div',{staticClass:"advantage-list"},_vm._l((_vm.tabList[_vm.activeTab].advantages),function(item,index){return _c('div',{key:index,staticClass:"advantage"},[_c('img',{attrs:{"src":require("@/assets/images/partner/success.png"),"alt":""}}),_vm._v(" "+_vm._s(item)+" ")])}),0),_c('img',{staticClass:"tab-content-info-img",attrs:{"src":require("@/assets/images/partner/service-bg2.png"),"alt":""}})])])]):_vm._e()])]),_c('section',{staticClass:"process"},[_c('div',{staticClass:"process-content"},[_c('div',{staticClass:"total-process",class:{
            'pre-hide': true,
            'move-bottomProcess': _vm.isShowProcess,
          }},[_c('div',{staticClass:"title"},[_vm._v("三步流程 轻松成为合伙人")]),_c('div',{staticClass:"content"},_vm._l((_vm.totalProcessList),function(item,index){return _c('div',{key:'total' + index,staticClass:"process-item"},[_c('div',{staticClass:"item-box"},[_c('img',{staticClass:"item-img",attrs:{"src":_vm.processImgs[item.img],"alt":""}}),_c('div',{staticClass:"item-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"item-content"},[_vm._v(_vm._s(item.content))])]),(index !== _vm.totalProcessList.length - 1)?_c('img',{staticClass:"right-arrow",attrs:{"src":require("@/assets/images/partner/right-arrow.png"),"alt":""}}):_vm._e()])}),0)]),_c('div',{staticClass:"threshold",class:{
            'pre-hide': true,
            'move-bottomThreshold': _vm.isShowThreshold,
          }},[_c('div',{staticClass:"title"},[_vm._v("0门槛成为十角兽合伙人")]),_vm._m(1)]),_c('div',{staticClass:"need-you",class:{
            'pre-hide': true,
            'move-bottomNeed': _vm.isShowNeed,
          }},[_c('div',{staticClass:"title"},[_vm._v("没错，我们的合伙人就是你")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"card-list"},_vm._l((_vm.needCardList),function(item,index){return _c('div',{key:'need' + index,staticClass:"card-item",on:{"mouseover":function($event){return _vm.clickNeedCard(index)}}},[_c('img',{staticClass:"card-img",class:{
                    'active-card-img': _vm.activeNeedCard === index,
                  },attrs:{"src":_vm.activeNeedCard === index
                      ? _vm.needImgs[item.activeImg]
                      : _vm.needImgs[item.img],"alt":""}}),(_vm.activeNeedCard !== index)?_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"card-title"},[_vm._v(_vm._s(item.title))])]):_c('div',{staticClass:"active-card-content"},[_c('div',{staticClass:"card-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"card-text"},[_vm._v(_vm._s(item.content))])])])}),0)])]),_c('div',{staticClass:"interests",class:{
            'pre-hide': true,
            'move-bottomInterests': _vm.isShowInterests,
          }},[_c('div',{staticClass:"title"},[_vm._v("合伙人权益")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"interests-list"},_vm._l((_vm.interestsCardList),function(item,index){return _c('div',{key:'interests' + index,staticClass:"interests-item"},[(item.type === 'equity')?_c('div',{staticClass:"equity-box"},[_c('div',{staticClass:"equity-box-top"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"equity-box-bottom"},_vm._l((item.children),function(childItem,childIndex){return _c('div',{key:'equity' + childIndex},[_vm._v(" "+_vm._s(childItem)+" ")])}),0)]):_vm._e(),(item.type === 'profit')?_c('div',{staticClass:"profit-box"},[_c('div',{staticClass:"profit-box-top"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"profit-box-bottom"},_vm._l((item.children),function(equityItem,equityIndex){return _c('div',{key:'profit' + equityIndex},[_vm._v(" "+_vm._s(equityItem)+" ")])}),0)]):_vm._e(),(
                    ['threshold', 'time', 'proportion'].includes(item.type)
                  )?_c('div',{staticClass:"other-box"},[_c('div',{staticClass:"other-box-top"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"other-box-bottom"},[_vm._v(_vm._s(item.content))])]):_vm._e()])}),0)])])])]),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.adsorptionIsShow),expression:"adsorptionIsShow"}],staticClass:"adsorption-box",class:{
        'top-hide': !_vm.isShowAdsorptionHide,
        'move-top': _vm.isShowAdsorption,
      }},[_c('div',{staticClass:"adsorption-form"},[_c('el-form',{ref:"adsorptionFormRef",attrs:{"model":_vm.joinForm,"label-width":"0px"}},[_c('el-form-item',[_c('el-input',{attrs:{"placeholder":"请输入您的姓名"},model:{value:(_vm.joinForm.name),callback:function ($$v) {_vm.$set(_vm.joinForm, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"joinForm.name"}})],1),_c('el-form-item',[_c('el-input',{attrs:{"placeholder":"请输入您的手机号"},model:{value:(_vm.joinForm.mobile),callback:function ($$v) {_vm.$set(_vm.joinForm, "mobile", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"joinForm.mobile"}})],1)],1),_c('div',{staticClass:"adsorption-btn",on:{"click":_vm.applyJoin}},[_vm._v("申请加盟")])],1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"difficulty-content"},[_c('div',{staticClass:"title"},[_vm._v("您是否遇到以下瓶颈")]),_c('img',{attrs:{"src":require("@/assets/images/partner/difficulty.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('img',{staticClass:"threshold-img",attrs:{"src":require("@/assets/images/partner/threshold-img.png"),"alt":""}}),_c('div',{staticClass:"text-box"},[_c('div',{staticClass:"text-title"},[_vm._v("加盟支持")]),_c('div',{staticClass:"text-content"},[_vm._v(" 即享十角兽全方位产品与服务支持，有效解决您企业服务产品差异化不足的问题，拓宽您的获客渠道，提升客户黏性，提高客户复购率，打造全新数字化工商财税+传统企业服务新生态 ")])])])
}]

export { render, staticRenderFns }
<template>
  <section class="section">
    <div class="yszc-wrapper">
      <div class="yszc-panel">
        <div class="yszc-lft">
          <div
            :class="{ title: true, 'pre-hide': true, 'move-bottom1': isShowDH }"
          >
            {{ productInfo.title1 }}
          </div>
          <div
            :class="{
              subTitle: true,
              'pre-hide': true,
              'move-bottom2': isShowDH,
            }"
          >
            {{ productInfo.subTitle1 }}
          </div>
          <div
            :class="{
              'step-cell': true,
              'pre-hide': true,
              'move-bottom3': isShowDH,
            }"
          >
            <div class="step-item step-1">
              <div class="img"></div>
              <div class="txt">{{ productInfo.step1[0] }}</div>
            </div>
            <div class="line"></div>
            <div class="step-item step-2">
              <div class="img"></div>
              <div class="txt">{{ productInfo.step1[1] }}</div>
            </div>
            <div class="line"></div>
            <div class="step-item step-3">
              <div class="img"></div>
              <div class="txt">{{ productInfo.step1[2] }}</div>
            </div>
            <div class="line"></div>
            <div class="step-item step-4">
              <div class="img"></div>
              <div class="txt">{{ productInfo.step1[3] }}</div>
            </div>
            <div class="line"></div>
            <div class="step-item step-5">
              <div class="img"></div>
              <div class="txt">{{ productInfo.step1[4] }}</div>
            </div>
          </div>
          <div
            :class="{
              'smty-cell': true,
              'pre-hide': true,
              'move-bottom4': isShowDH,
            }"
          >
            <div class="qrcode"></div>
            <div class="txt">扫码体验</div>
            <div class="sjx"></div>
          </div>
        </div>
        <div
          :class="{
            'yszc-rgt': true,
            'pre-hide': true,
            'move-bottom1': isShowDH,
          }"
        >
          <div class="sj"></div>
        </div>
      </div>
      <!-- <div class="yszc-zs"></div> -->
      <div class="yszc-yy"></div>
    </div>
    <!-- 智能财税模块 -->
    <div class="zncs-wrapper">
      <div class="zncs-panel">
        <div
          :class="{
            'zncs-lft': true,
            'pre-hide': true,
            'move-bottom1': isShowDH2,
          }"
        >
          <div class="sjyy">
            <div class="sj"></div>
          </div>
        </div>
        <div class="zncs-rgt">
          <div
            :class="{
              title: true,
              'pre-hide': true,
              'move-bottom1': isShowDH2,
            }"
          >
            {{ productInfo.title2 }}
          </div>
          <div
            :class="{
              subTitle: true,
              'pre-hide': true,
              'move-bottom2': isShowDH2,
            }"
          >
            {{ productInfo.subTitle2 }}<span class="num">2000</span>元
          </div>
          <div
            :class="{
              'step-cell': true,
              'pre-hide': true,
              'move-bottom3': isShowDH2,
            }"
          >
            <div class="step-item step-1">
              <div class="img"></div>
              <div class="txt">{{ productInfo.step2[0] }}</div>
            </div>
            <div class="line"></div>
            <div class="step-item step-2">
              <div class="img"></div>
              <div class="txt">{{ productInfo.step2[1] }}</div>
            </div>
            <div class="line"></div>
            <div class="step-item step-3">
              <div class="img"></div>
              <div class="txt">{{ productInfo.step2[2] }}</div>
            </div>
          </div>
          <div
            :class="{
              'smty-cell': true,
              'pre-hide': true,
              'move-bottom4': isShowDH2,
            }"
          >
            <div class="sjx"></div>
            <div class="txt">扫码体验</div>
            <div class="qrcode"></div>
          </div>
        </div>
      </div>
      <div
        :class="{ 'zncs-zs': true, 'left-hide': true, 'move-left1': isShowDH4 }"
      ></div>
    </div>
    <!-- 合作伙伴模块 -->
    <div class="hzhb-wrapper">
      <div class="hzhb-panel">
        <div
          :class="{ title: true, 'pre-hide': true, 'move-bottom1': isShowDH3 }"
        >
          生态合作伙伴
        </div>
        <div
          :class="{ zs: true, 'pre-hide': true, 'move-bottom1': isShowDH5 }"
        ></div>
        <div class="img-list">
          <div
            :class="{
              'img-cell1': true,
              'pre-hide': true,
              'move-bottom2': isShowDH3,
            }"
          >
            <div class="img1"></div>
            <div class="img2"></div>
            <div class="img3"></div>
            <div class="img4"></div>
          </div>
          <div
            :class="{
              'img-cell2': true,
              'pre-hide': true,
              'move-bottom3': isShowDH3,
            }"
          >
            <div class="img5"></div>
            <div class="img6"></div>
            <div class="img7"></div>
            <div class="img8"></div>
          </div>
          <div
            :class="{
              'img-cell3': true,
              'pre-hide': true,
              'move-bottom4': isShowDH3,
            }"
          >
            <div class="img9"></div>
            <div class="img10"></div>
            <div class="img11"></div>
            <div class="img12"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { productInfo } from '@/config'

export default {
  name: 'Product',
  computed: {
    productInfo() {
      return productInfo
    },
  },
  data() {
    return {
      isShowDH: false,
      isShowDH2: false,
      isShowDH3: false,
      isShowDH4: false,
      isShowDH5: false,
    }
  },
  inject: ['getPosition', 'scrollTo'],
  methods: {
    onScroll() {
      const { scrollTop } = this.getPosition()
      console.log(scrollTop)
      const clientHeight = document.documentElement.clientHeight
      if (clientHeight <= 1150) {
        if (scrollTop >= 400) {
          this.isShowDH = true
        }
        if (scrollTop >= 1100) {
          this.isShowDH2 = true
        }
        if (scrollTop >= 2000) {
          this.isShowDH3 = true
        }
        if (scrollTop >= 1300) {
          this.isShowDH4 = true
        }
        if (scrollTop >= 2400) {
          this.isShowDH5 = true
        }
      } else {
        this.isShowDH = true
        if (scrollTop >= 300) {
          this.isShowDH2 = true
        }
        if (scrollTop >= 1200) {
          this.isShowDH3 = true
        }
        if (scrollTop >= 500) {
          this.isShowDH4 = true
        }
        if (scrollTop >= 1600) {
          this.isShowDH5 = true
        }
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll, true)
    const clientHeight = document.documentElement.clientHeight
    if (clientHeight > 1150) {
      this.isShowDH = true
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll, true)
  },
}
</script>

<style scoped lang="less">
.yszc-wrapper {
  width: 100%;
  height: 857px;
  background: #fff;
  position: relative;
  z-index: 2;
  .yszc-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 250px;
    .yszc-lft {
      margin-right: 73px;
      padding-top: 30px;
      .title {
        font-size: 48px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #102a55;
        margin-bottom: 20px;
      }
      .subTitle {
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #102a55;
        margin-bottom: 50px;
        // opacity: 0.6;
      }
      .step-cell {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 70px;
        .line {
          width: 52px;
          height: 1px;
          border-top: 1px solid #c3daff;
          margin: 0 1px;
        }
        .step-item {
          text-align: center;
          // margin-right: 55px;
          .img {
            width: 88px;
            height: 88px;
            background-image: url('../assets/images/2.0/step1-1.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
          .txt {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #102a55;
          }
        }
        .step-2 {
          .img {
            background-image: url('../assets/images/2.0/step1-2.png');
          }
        }
        .step-3 {
          .img {
            background-image: url('../assets/images/2.0/step1-3.png');
          }
        }
        .step-4 {
          .img {
            background-image: url('../assets/images/2.0/step1-4.png');
          }
        }
        .step-5 {
          .img {
            background-image: url('../assets/images/2.0/step1-5.png');
          }
        }
      }
      .smty-cell {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .qrcode {
          width: 128px;
          height: 128px;
          background-image: url('../assets/images/2.0/yszc.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-right: 16px;
        }
        .txt {
          flex-shrink: 1;
          width: 15px;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #102a55;
          margin-right: 16px;
          opacity: 0.5;
          line-height: 20px;
        }
        .sjx {
          width: 9px;
          height: 15px;
          background-image: url('../assets/images/2.0/sjx.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
    }
    .yszc-rgt {
      .sj {
        width: 344px;
        height: 609px;
        background-image: url('../assets/images/cp/yszc-sj.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }
  .yszc-zs {
    position: absolute;
    left: 0px;
    top: -108px;
    width: 562px;
    height: 584px;
    background-image: url('../assets/images/cp/yszc-zs.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .yszc-yy {
    position: absolute;
    right: 0px;
    bottom: -314px;
    width: 474px;
    height: 627px;
    background-image: url('../assets/images/cp/yszc-sj-yy.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.zncs-wrapper {
  width: 100%;
  height: 982px;
  background: #ffffff;
  // overflow: hidden;
  position: relative;
  .zncs-panel {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 255px;
    padding-bottom: 90px;
    box-sizing: border-box;
    .zncs-lft {
      margin-right: 0px;
      .sjyy {
        width: 718px;
        height: 717px;
        background-image: url('../assets/images/cp/zncs-sj-yy.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: relative;
        .sj {
          position: absolute;
          left: -160px;
          top: -70px;
          width: 772px;
          height: 827px;
          background-image: url('../assets/images/cp/zncs-sj.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
    }
    .zncs-rgt {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex-direction: column;
      .title {
        font-size: 48px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #102a55;
        text-align: right;
        margin-bottom: 16px;
      }
      .subTitle {
        flex-shrink: 1;
        width: 260px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #102a55;
        // opacity: 0.5;
        margin-bottom: 26px;
        text-align: right;
        line-height: 40px;
        span {
          font-size: 26px;
        }
      }
      .step-cell {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 75px;
        .line {
          width: 52px;
          height: 1px;
          border-top: 1px solid #c3daff;
          margin: 0 1px;
        }
        .step-item {
          text-align: center;
          // margin-right: 55px;
          .img {
            width: 88px;
            height: 88px;
            background-image: url('../assets/images/2.0/step2-1.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
          .txt {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #102a55;
          }
        }
        .step-2 {
          .img {
            background-image: url('../assets/images/2.0/step2-2.png');
          }
        }
        .step-3 {
          .img {
            background-image: url('../assets/images/2.0/step2-3.png');
          }
        }
      }
      .smty-cell {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .qrcode {
          width: 128px;
          height: 128px;
          background-image: url('../assets/images/2.0/zncs.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-left: 16px;
        }
        .txt {
          flex-shrink: 1;
          width: 15px;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #102a55;
          margin-left: 16px;
          opacity: 0.5;
          line-height: 20px;
        }
        .sjx {
          width: 9px;
          height: 15px;
          background-image: url('../assets/images/2.0/sjx.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          transform: rotateY(180deg);
        }
      }
    }
  }
  .zncs-zs {
    position: absolute;
    left: -170px;
    bottom: -50px;
    width: 374px;
    height: 485px;
    z-index: 2;
    background-image: url('../assets/images/cp/zncs-zs.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.hzhb-wrapper {
  height: 767px;
  background-color: #fff;
  position: relative;
  .zs {
    position: absolute;
    right: 0;
    bottom: -79px;
    width: 226px;
    height: 309px;
    background-image: url('../assets/images/hzhb/hzhb-zs.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .hzhb-panel {
    width: 1024px;
    margin: 0 auto;
    .title {
      width: 100%;
      font-size: 60px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #102a55;
      text-align: center;
      margin-bottom: 88px;
    }

    .img-cell1 {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
      .img1 {
        width: 226px;
        height: 90px;
        background-image: url('../assets/images/hzhb/img1.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .img2 {
        width: 226px;
        height: 90px;
        background-image: url('../assets/images/hzhb/img2.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-left: 40px;
      }
      .img3 {
        width: 226px;
        height: 90px;
        background-image: url('../assets/images/hzhb/img3.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-left: 40px;
      }
      .img4 {
        width: 226px;
        height: 90px;
        background-image: url('../assets/images/hzhb/img4.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-left: 40px;
      }
    }
    .img-cell2 {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 40px;
      .img5 {
        width: 226px;
        height: 90px;
        background-image: url('../assets/images/hzhb/img5.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .img6 {
        width: 226px;
        height: 90px;
        background-image: url('../assets/images/hzhb/img6.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-left: 40px;
      }
      .img7 {
        width: 226px;
        height: 90px;
        background-image: url('../assets/images/hzhb/img7.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-left: 40px;
      }
      .img8 {
        width: 226px;
        height: 90px;
        background-image: url('../assets/images/hzhb/img8.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-left: 40px;
      }
    }
    .img-cell3 {
      display: flex;
      justify-content: center;
      align-items: center;
      .img9 {
        width: 226px;
        height: 90px;
        background-image: url('../assets/images/hzhb/img9.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .img10 {
        width: 226px;
        height: 90px;
        background-image: url('../assets/images/hzhb/img10.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-left: 40px;
      }
      .img11 {
        width: 226px;
        height: 90px;
        background-image: url('../assets/images/hzhb/img11.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-left: 40px;
      }
      .img12 {
        width: 226px;
        height: 90px;
        background-image: url('../assets/images/hzhb/img12.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-left: 40px;
      }
    }
  }
}
.pre-hide {
  transform: translateY(100px);
  opacity: 0;
}
.move-bottom1 {
  animation: move-bottom 0.8s forwards ease-in-out;
}
.move-bottom2 {
  animation: move-bottom 0.8s 0.1s forwards ease-in-out;
}
.move-bottom3 {
  animation: move-bottom 0.8s 0.2s forwards ease-in-out;
}
.move-bottom4 {
  animation: move-bottom 0.8s 0.3s forwards ease-in-out;
}

.left-hide {
  transform: translateX(-150px);
  opacity: 0;
}
.right-hide {
  transform: translateX(150px);
  opacity: 0;
}
.move-left1 {
  animation: move-left 0.5s 0s forwards ease-in-out;
}
.move-right1 {
  animation: move-right 0.5s 0s forwards ease-in-out;
}

@keyframes move-bottom {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes move-left {
  0% {
    transform: translateX(-150px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes move-right {
  0% {
    transform: translateX(150px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
</style>

<template>
  <div>
    <Navgation />
    <div class="container">
      <Jumbotron :url="isMobile ? 'case-m-banner' : 'success-case'" />
      <div class="con-one">
        <div class="title">降本增效 就在十角兽</div>
        <div class="desc">全国累计100000+的小微企业都在使用十角兽</div>
        <div class="box">
          <div class="left">
            <div class="func">
              <div class="card one">
                <div class="card-con">
                  <svg-icon icon-class="case-icon-1" class-name="case" />
                  <div class="name">AI记账报税</div>
                  <div class="desc">智能解析，科学记账，安全申报，高准确性备份</div>
                </div>
              </div>
              <div class="card two">
                <div class="card-con">
                  <svg-icon icon-class="case-icon-2" class-name="case" />
                  <div class="name">打破行业壁垒</div>
                  <div class="desc">拍照扫描识别，轻松记流水账，降低对财税专业的依赖</div>
                </div>
              </div>
              <div class="card three">
                <div class="card-con">
                  <svg-icon icon-class="case-icon-3" class-name="case" />
                  <div class="name">高效信息流转</div>
                  <div class="desc">手机里的财税专家，随时随地，省时省力，安心又时效</div>
                </div>
              </div>
              <div class="card four">
                <div class="card-con">
                  <svg-icon icon-class="case-icon-4" class-name="case" />
                  <div class="name">低成本高效率</div>
                  <div class="desc">记账报税，只需一杯咖啡的时间，只需一杯咖啡的价格</div>
                </div>
              </div>
              <div class="cross"></div>
            </div>
          </div>
          <div class="right">
            <div class="nums">
              <div class="card" v-for="item in numsList" :key="item.key">
                <div class="con">
                  <div class="num" ref="numberGrow">{{ item.num }}</div>
                  <div class="unit">{{ item.unit }}</div>
                </div>
                <span class="detail">{{ item.detail }}</span>
              </div>
            </div>
            <div class="method" v-if="!isMobile">
              计算方法：每月把原始单据寄送给第三方机构到出账至少需要5天计算，十角兽实时出账，票进账出，为了方便计算，取整1h；5*24-1=119h；以每月支付给第三方机构200元计算,14*200-468=2332/14=166.57*12=1998.86h
            </div>
          </div>
        </div>
      </div>
      <div class="con-two" v-if="!isMobile">
        <div class="title">客户之声</div>
        <div class="desc">全国累计100000+看看来自不同行业的小微客户的真实评价</div>
        <swiper class="swiper" :options="swiperOption" key="pSwiper">
          <swiper-slide v-for="item in userList" :key="item.key">
            <div class="slide-con">
              <div class="company">
                <svg-icon icon-class="case-user-compony" class-name="icon"></svg-icon>
                <span class="name">{{ item.company }}</span>
              </div>
              <div class="content">{{ item.content }}</div>
              <svg-icon icon-class="case-user-divider" class-name="btn-divider"></svg-icon>
              <div class="user">
                <img :src="require(`@/assets/images/case-user-ava${item.key}.png`)" />
                <span class="name">{{ item.name }}</span>
                <span class="position">{{ item.position }}</span>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev">
            <div class="swiper-btn">
              <svg-icon icon-class="case-swiper-left" class-name="icon"></svg-icon>
            </div>
          </div>
          <div class="swiper-button-next" slot="button-next">
            <div class="swiper-btn">
              <svg-icon icon-class="case-swiper-right" class-name="icon"></svg-icon>
            </div>
          </div>
        </swiper>
      </div>
      <div class="con-two" v-else>
        <div class="title">客户之声</div>
        <div class="desc">看看来自不同行业的中小微客户的真实评价</div>
        <swiper class="m-swiper" :options="mSwiperOption" key="mSwiper">
          <swiper-slide v-for="item in userList" :key="item.key">
            <div class="m-slide-con">
              <div class="m-company">
                <svg-icon icon-class="case-user-compony" class-name="m-icon"></svg-icon>
                <span class="m-name">{{ item.company }}</span>
              </div>
              <div class="m-content">{{ item.content }}</div>
            </div>
          </swiper-slide>
          <div class="m-swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <div class="con-three">
        <div class="title">智能财税 就在十角兽</div>
        <img src="@/assets/images/case-qrcode.png" />
        <div class="desc">微信扫描二维码抢先体验吧</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Jumbotron } from '@/base'
import checkIsMobile from '@/mixin/checkIsMobile'
import Navgation from '@/layouts/components/Navgation'

export default {
  name: 'Case',
  mixins: [checkIsMobile],
  filters: {},
  components: { Jumbotron,Navgation },
  props: {},
  data() {
    return {
      numsList: [
        { key: '1', num: 119, unit: '小时', detail: '缩短每月提前看账时间' },
        { key: '2', num: 1428, unit: '小时', detail: '缩短每年提前看账时间' },
        { key: '3', num: 166.6, unit: '元', detail: '每月至少能节省' },
        { key: '4', num: 1998.9, unit: '元', detail: '每年至少能节省' },
      ],
      userList: [
        {
          key: '1',
          company: '铜陵北巷装饰工程有限公司',
          content:
            '刚开始为注册公司发愁，完全不知道要准备什么材料，跑了好几次工商局都没办下来营业执照。后来用了十角兽云上注册小程序，3天营业执照就下来了，非常方便。而且十角兽还支持自己记账报税，一个月十几张票，几分钟就完成报税了，比自己找代账公司还省了不少钱，很满意。',
          name: '俞先生',
          position: '企业合伙人',
        },
        {
          key: '2',
          company: '上海元苍网络科技有限公司 ',
          content:
            '我们以前找了本地一家比较大的代账公司，但可能因为我们是小公司吧，总感觉代账会计信息回复得慢，很少联系我们。后来听说了十角兽代账机器人，抱着试试看的心态使用了下，刚开始还担心不会操作，但是十角兽的客服很热心，教了不到20分钟，自己就能完成记账报税操作了。现在每个月用十角兽记账报税，并且数据和业务进度自己随时都能看到，很放心。',
          name: '陈先生',
          position: '企业创始人',
        },
        {
          key: '3',
          company: '深圳市钝感力教育发展有限公司 ',
          content:
            '十角兽代账机器人这款小程序太好用了，我每月点点就把税报了，超级方便。在用这款产品前，我们家企业业务量不大，每月都没几张票，找代账公司报税价格比较高，所以我每月都让我学财务的朋友帮忙报，后来无意间在微信上搜代账，搜到了十角兽代账机器人，尝试了用一下 我就点点税就报了，我让我财务的朋友还看了一下这款产品，把我朋友都惊到了，他都感觉他要失业了。',
          name: '潘先生',
          position: '企业联合创始人',
        },
      ],
      swiperOption: {
        loop: true,
        autoplay: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      mSwiperOption: {
        effect: 'coverflow',
        loop: true,
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        coverflowEffect: {
          rotate: 0,
          stretch: 200,
          depth: 100,
          slideShadows: false,
          modifier: 1,
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.m-swiper-pagination',
          type: 'custom',
          renderCustom: (swiper, current) => {
            const html = `<div class="m-step-list">
            <span class="step-item ${current === 1 && 'active'}"></span>
            <span class="step-item ${current === 2 && 'active'}"></span>
            <span class="step-item ${current === 3 && 'active'}"></span>
            </div>`
            return html
          },
        },
      },
    }
  },
  computed: {},
  watch: {},
  mounted() {
    const arr = this.$refs.numberGrow
    for (let i = 0; i < arr.length; i++) {
      this.numberGrow(arr[i])
    }
  },
  created() {},
  methods: {
    numberGrow(ele) {
      const value = parseFloat(ele.innerText || 0)
      const n = value.toString().split('.')?.[1]?.length || 0
      let step = (value * 10) / 1000
      let current = 0
      let start = 0
      let t = setInterval(function () {
        start += step
        if (start > value) {
          clearInterval(t)
          start = value
          t = null
        }
        if (current === start) {
          return
        }
        current = start
        ele.innerHTML = parseFloat(current.toString().replace(/(d)(?=(?:d{3}[+]?)+$)/g, '$1,')).toFixed(n)
      }, 10)
    },
  },
}
</script>

<style scoped lang="less">
@media (min-width: 768px) {
  .container {
    margin-top: @navHeight;
    .con-one {
      padding: 75px 0;
      text-align: center;
      .title {
        margin: 64px 0 8px 0;
        font-size: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 40px;
      }
      .desc {
        text-align: center;
        margin-top: 12px;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 25px;
        margin-bottom: 30px;
      }

      .box {
        display: flex;
        .left {
          width: 50%;
          display: flex;
          justify-content: flex-end;
          padding-right: 160px;
          .func {
            width: 400px;
            height: 400px;
            background: linear-gradient(180deg, #f3f6f8 50%, #ffffff 100%);
            box-shadow: 0px 6px 15px 0px rgba(39, 51, 71, 0.06);
            border-radius: 50px;
            cursor: pointer;
            position: relative;
            border-radius: 50px;
            overflow: hidden;
            border: 2px solid #ffffff;
            .cross {
              margin: 200px auto;
              width: 0;
              height: 0;
              position: relative;
            }
            .cross:before,
            .cross:after {
              content: '';
              position: absolute;
              background: #e4e4e4;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            .cross:before {
              width: 400px;
              height: 1px;
            }
            .cross:after {
              width: 1px;
              height: 400px;
            }
            .card {
              width: 200px;
              height: 200px;
              display: flex;
              box-sizing: border-box;
              align-items: center;
              justify-content: center;
              .card-con {
                text-align: center;
              }
              .name {
                font-size: 14px;
                font-weight: bold;
              }
              .desc {
                display: none;
                color: #fff;
                font-size: 16px;
                line-height: 30px;
                width: 80%;
                margin: 0 auto;
                margin-top: 20px;
              }
              .case {
                width: 29px;
                height: 28px;
                margin-bottom: 16px;
              }
              transition: width 0.5s ease-in-out, height 0.5s ease-in-out, border-radius 0.5s ease-in-out, left 0.5s ease-in-out, top 0.5s ease-in-out;
              &:hover {
                .case {
                  color: #fff;
                }
                .name {
                  color: #fff;
                }
                .desc {
                  display: block;
                }
                background: #3860f4;
                border-radius: 50px;
                z-index: 100;
                width: 100%;
                height: 100%;
                transition: width 0.5s ease-in-out, height 0.5s ease-in-out, border-radius 0.5s ease-in-out, left 0.5s ease-in-out, top 0.5s ease-in-out;
              }
            }
            .one {
              position: absolute;
            }
            .two {
              position: absolute;
              left: 50%;
              &:hover {
                left: 0;
              }
            }
            .three {
              position: absolute;
              top: 50%;
              &:hover {
                top: 0;
              }
            }
            .four {
              position: absolute;
              left: 50%;
              top: 50%;
              &:hover {
                top: 0;
                left: 0;
              }
            }
          }
        }
        .right {
          width: 50%;
          text-align: left;
          .nums {
            width: 560px;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 48px;
          }
          .method {
            width: 480px;
            height: 60px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
          }
          .card {
            width: 50%;
            margin: 15px 0;
            .con {
              display: flex;
              align-items: flex-end;
              margin-bottom: 12px;
              .num {
                font-size: 56px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #2761ff;
                line-height: 78px;
              }
              .unit {
                padding-left: 8px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 22px;
              }
            }
            .detail {
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 22px;
            }
          }
        }
      }
    }
    .con-two {
      background: #f5f8fc;
      padding: 48px 0;
      text-align: center;
      .title {
        margin: 64px 0 8px 0;
        font-size: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 40px;
      }
      .desc {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        line-height: 22px;
        color: #333333;
        line-height: 17px;
        margin-bottom: 76px;
      }
    }
    .con-three {
      background-image: url('~@/assets/images/grid-backgound.png');
      background-repeat: no-repeat;
      background-size: 100% 400px;
      background-position: bottom;
      padding-bottom: 173px;
      text-align: center;
      .title {
        margin-top: 100px;
        font-size: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 40px;
      }
      img {
        width: 212px;
        height: 212px;
      }
      .desc {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        text-indent: 10px;
      }
    }
    .swiper {
      width: 1104px;
      .slide-con {
        width: 738px;
        margin: 0 auto;
        text-align: left;
        .company {
          display: flex;
          margin-bottom: 18px;
          .icon {
            width: 28px;
            height: 32px;
          }
          .name {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 25px;
            padding-left: 12px;
          }
        }
        .content {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 36px;
        }
        .btn-divider {
          width: 56px;
          height: 3px;
          margin: 32px 0 47px 0;
        }
        .user {
          display: flex;
          align-items: center;
          img {
            width: 48px;
            height: 48px;
          }
          .name {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 22px;
            padding-left: 24px;
          }
          .position {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #999999;
            line-height: 22px;
            padding-left: 24px;
          }
        }
      }
      .swiper-button-prev {
        transform: translateY(-50%) !important;
        .swiper-btn {
          position: relative;
          left: 10px;
          min-width: 60px;
          height: 60px;
          font-size: 20px;
          background: #ffffff;
          display: flex;
          border-radius: 30px;
          align-items: center;
          justify-content: center;
          .icon {
            color: #cccccc;
            position: relative;
            right: 2px;
          }
          &:hover {
            .icon {
              color: #333333;
            }
          }
        }
      }
      .swiper-button-next {
        transform: translateY(-50%) !important;
        .swiper-btn {
          position: relative;
          right: 10px;
          min-width: 60px;
          height: 60px;
          font-size: 20px;
          background: #ffffff;
          display: flex;
          border-radius: 30px;
          align-items: center;
          justify-content: center;
          .icon {
            color: #cccccc;
            position: relative;
            left: 2px;
          }
          &:hover {
            .icon {
              color: #333333;
            }
          }
        }
      }
      .swiper-button-prev:after,
      .swiper-button-next:after {
        content: unset;
      }
    }
  }
}
@media (max-width: 768px) {
  .container {
    .con-one {
      .title {
        text-align: center;
        margin-top: 44px;
        font-size: 36px;
        font-weight: bold;
        color: #333333;
        line-height: 50px;
      }
      .desc {
        text-align: center;
        margin-top: 12px;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 25px;
        margin-bottom: 30px;
      }

      .box {
        .left {
          display: flex;
          justify-content: center;
          .func {
            width: 550px;
            height: 550px;
            background: linear-gradient(180deg, #f3f6f8 50%, #ffffff 100%);
            box-shadow: 0px 6px 15px 0px rgba(39, 51, 71, 0.06);
            border-radius: 50px;
            cursor: pointer;
            position: relative;
            border-radius: 50px;
            overflow: hidden;
            border: 2px solid #ffffff;
            .cross {
              margin: 50% auto;
              width: 0;
              height: 0;
              position: relative;
            }
            .cross:before,
            .cross:after {
              content: '';
              position: absolute;
              background: #e4e4e4;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            .cross:before {
              width: 550px;
              height: 1px;
            }
            .cross:after {
              width: 1px;
              height: 550px;
            }
            .card {
              width: 50%;
              height: 50%;
              display: flex;
              box-sizing: border-box;
              align-items: center;
              justify-content: center;
              .card-con {
                text-align: center;
              }
              .name {
                font-size: 24px;
                font-weight: bold;
              }
              .desc {
                display: none;
                color: #fff;
                font-size: 28px;
                line-height: 60px;
                width: 80%;
                margin: 0 auto;
                margin-top: 40px;
              }
              .case {
                width: 44px;
                height: 42px;
                margin-bottom: 24px;
              }
              transition: width 0.5s ease-in-out, height 0.5s ease-in-out, border-radius 0.5s ease-in-out, left 0.5s ease-in-out, top 0.5s ease-in-out;
              &:hover {
                .case {
                  color: #fff;
                }
                .name {
                  color: #fff;
                }
                .desc {
                  display: block;
                }
                background: #3860f4;
                border-radius: 50px;
                z-index: 100;
                width: 100%;
                height: 100%;
                transition: width 0.5s ease-in-out, height 0.5s ease-in-out, border-radius 0.5s ease-in-out, left 0.5s ease-in-out, top 0.5s ease-in-out;
              }
            }
            .one {
              position: absolute;
            }
            .two {
              position: absolute;
              left: 50%;
              &:hover {
                left: 0;
              }
            }
            .three {
              position: absolute;
              top: 50%;
              &:hover {
                top: 0;
              }
            }
            .four {
              position: absolute;
              left: 50%;
              top: 50%;
              &:hover {
                top: 0;
                left: 0;
              }
            }
          }
        }
        .right {
          display: flex;
          justify-content: center;
          margin-bottom: 45px;
          margin-top: 30px;
          .nums {
            position: relative;
            left: 50px;
            width: 650px;
            display: flex;
            flex-wrap: wrap;
          }
          .card {
            width: 50%;
            margin: 15px 0;
            .con {
              display: flex;
              align-items: flex-end;
              margin-bottom: 45px;
              .num {
                font-size: 72px;
                font-weight: 500;
                color: #2761ff;
              }
              .unit {
                padding-left: 6px;
                font-size: 20px;
                font-weight: 400;
                color: #333333;
                position: relative;
                bottom: 10px;
              }
            }
            .detail {
              font-size: 20px;
              font-weight: 400;
              color: #999999;
              line-height: 28px;
            }
          }
        }
      }
    }
    .con-two {
      background: #fafbfe;
      padding-top: 88px;
      .title {
        text-align: center;
        font-size: 50px;
        font-weight: bold;
        color: #333333;
        line-height: 50px;
      }
      .desc {
        text-align: center;
        margin-top: 24px;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 17px;
        margin-bottom: 44px;
      }
      .m-swiper {
        width: calc(750px - 100px);
        margin: 0 auto;
        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 520px;
          text-align: center;
        }
        .m-slide-con {
          width: 436px;
          height: 400px;
          background: linear-gradient(180deg, #f3f6f8 0%, #ffffff 100%);
          box-shadow: 0px 8px 20px 0px rgba(39, 51, 71, 0.06);
          border-radius: 2px;
          border: 2px solid #ffffff;
          padding: 44px;
          .m-company {
            display: flex;
            margin-bottom: 16px;
            .m-icon {
              width: 36px;
              height: 42px;
            }
            .m-name {
              font-size: 24px;
              color: #000000;
              line-height: 33px;
              font-weight: bold;
              padding-left: 20px;
            }
          }
          .m-content {
            font-size: 20px;
            color: #86909c;
            line-height: 40px;
            text-align: left;
          }
        }
        /deep/ .m-step-list {
          display: flex;
          justify-content: center;
          padding: 44px 0;
          .step-item {
            width: 52px;
            height: 3px;
            background: #2761ff;
            opacity: 0.31;
          }
          .step-item:not(:first-child) {
            margin-left: 12px;
          }
          .active {
            opacity: 1;
          }
        }
      }
    }
    .con-three {
      padding: 88px 0 80px 0;
      text-align: center;
      .title {
        font-size: 36px;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
      }
      img {
        width: 332px;
        height: 332px;
      }
      .desc {
        font-size: 24px;
        color: #333333;
        line-height: 33px;
      }
    }
  }
}
</style>

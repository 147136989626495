var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section"},[_c('div',{staticClass:"yszc-wrapper"},[_c('div',{staticClass:"yszc-panel"},[_c('div',{staticClass:"yszc-lft"},[_c('div',{class:{ title: true, 'pre-hide': true, 'move-bottom1': _vm.isShowDH }},[_vm._v(" "+_vm._s(_vm.productInfo.title1)+" ")]),_c('div',{class:{
            subTitle: true,
            'pre-hide': true,
            'move-bottom2': _vm.isShowDH,
          }},[_vm._v(" "+_vm._s(_vm.productInfo.subTitle1)+" ")]),_c('div',{class:{
            'step-cell': true,
            'pre-hide': true,
            'move-bottom3': _vm.isShowDH,
          }},[_c('div',{staticClass:"step-item step-1"},[_c('div',{staticClass:"img"}),_c('div',{staticClass:"txt"},[_vm._v(_vm._s(_vm.productInfo.step1[0]))])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"step-item step-2"},[_c('div',{staticClass:"img"}),_c('div',{staticClass:"txt"},[_vm._v(_vm._s(_vm.productInfo.step1[1]))])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"step-item step-3"},[_c('div',{staticClass:"img"}),_c('div',{staticClass:"txt"},[_vm._v(_vm._s(_vm.productInfo.step1[2]))])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"step-item step-4"},[_c('div',{staticClass:"img"}),_c('div',{staticClass:"txt"},[_vm._v(_vm._s(_vm.productInfo.step1[3]))])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"step-item step-5"},[_c('div',{staticClass:"img"}),_c('div',{staticClass:"txt"},[_vm._v(_vm._s(_vm.productInfo.step1[4]))])])]),_c('div',{class:{
            'smty-cell': true,
            'pre-hide': true,
            'move-bottom4': _vm.isShowDH,
          }},[_c('div',{staticClass:"qrcode"}),_c('div',{staticClass:"txt"},[_vm._v("扫码体验")]),_c('div',{staticClass:"sjx"})])]),_c('div',{class:{
          'yszc-rgt': true,
          'pre-hide': true,
          'move-bottom1': _vm.isShowDH,
        }},[_c('div',{staticClass:"sj"})])]),_c('div',{staticClass:"yszc-yy"})]),_c('div',{staticClass:"zncs-wrapper"},[_c('div',{staticClass:"zncs-panel"},[_c('div',{class:{
          'zncs-lft': true,
          'pre-hide': true,
          'move-bottom1': _vm.isShowDH2,
        }},[_vm._m(0)]),_c('div',{staticClass:"zncs-rgt"},[_c('div',{class:{
            title: true,
            'pre-hide': true,
            'move-bottom1': _vm.isShowDH2,
          }},[_vm._v(" "+_vm._s(_vm.productInfo.title2)+" ")]),_c('div',{class:{
            subTitle: true,
            'pre-hide': true,
            'move-bottom2': _vm.isShowDH2,
          }},[_vm._v(" "+_vm._s(_vm.productInfo.subTitle2)),_c('span',{staticClass:"num"},[_vm._v("2000")]),_vm._v("元 ")]),_c('div',{class:{
            'step-cell': true,
            'pre-hide': true,
            'move-bottom3': _vm.isShowDH2,
          }},[_c('div',{staticClass:"step-item step-1"},[_c('div',{staticClass:"img"}),_c('div',{staticClass:"txt"},[_vm._v(_vm._s(_vm.productInfo.step2[0]))])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"step-item step-2"},[_c('div',{staticClass:"img"}),_c('div',{staticClass:"txt"},[_vm._v(_vm._s(_vm.productInfo.step2[1]))])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"step-item step-3"},[_c('div',{staticClass:"img"}),_c('div',{staticClass:"txt"},[_vm._v(_vm._s(_vm.productInfo.step2[2]))])])]),_c('div',{class:{
            'smty-cell': true,
            'pre-hide': true,
            'move-bottom4': _vm.isShowDH2,
          }},[_c('div',{staticClass:"sjx"}),_c('div',{staticClass:"txt"},[_vm._v("扫码体验")]),_c('div',{staticClass:"qrcode"})])])]),_c('div',{class:{ 'zncs-zs': true, 'left-hide': true, 'move-left1': _vm.isShowDH4 }})]),_c('div',{staticClass:"hzhb-wrapper"},[_c('div',{staticClass:"hzhb-panel"},[_c('div',{class:{ title: true, 'pre-hide': true, 'move-bottom1': _vm.isShowDH3 }},[_vm._v(" 生态合作伙伴 ")]),_c('div',{class:{ zs: true, 'pre-hide': true, 'move-bottom1': _vm.isShowDH5 }}),_c('div',{staticClass:"img-list"},[_c('div',{class:{
            'img-cell1': true,
            'pre-hide': true,
            'move-bottom2': _vm.isShowDH3,
          }},[_c('div',{staticClass:"img1"}),_c('div',{staticClass:"img2"}),_c('div',{staticClass:"img3"}),_c('div',{staticClass:"img4"})]),_c('div',{class:{
            'img-cell2': true,
            'pre-hide': true,
            'move-bottom3': _vm.isShowDH3,
          }},[_c('div',{staticClass:"img5"}),_c('div',{staticClass:"img6"}),_c('div',{staticClass:"img7"}),_c('div',{staticClass:"img8"})]),_c('div',{class:{
            'img-cell3': true,
            'pre-hide': true,
            'move-bottom4': _vm.isShowDH3,
          }},[_c('div',{staticClass:"img9"}),_c('div',{staticClass:"img10"}),_c('div',{staticClass:"img11"}),_c('div',{staticClass:"img12"})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sjyy"},[_c('div',{staticClass:"sj"})])
}]

export { render, staticRenderFns }
<template>
  <div>
    <Navgation />
    <div class="container" v-if="!isMobile">
      <div class="bg-con-one">
        <div class="panel">
          <div class="left">
            <div class="title">十角兽，让记账报税更简单</div>
            <div class="desc">企业记账报税一定要找代理记账吗？十角兽无需专业知识，每月只需简单三步，轻松搞定记账报税；一杯咖啡的价格，低成本高收获。</div>
            <img src="@/assets/images/product-one-1.png" />
          </div>
          <div class="right">
            <img src="@/assets/images/product-one-0.png" />
          </div>
        </div>
      </div>
      <div class="bg-con-two">
        <div class="panel">
          <div class="title">产品服务范围</div>
          <div class="service">
            <div v-for="item in service" :key="item.key" class="card">
              <svg-icon class="icon" :icon-class="`product-two-${item.key}`"></svg-icon>
              <span class="text">{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-con-three" :style="{ background: `linear-gradient(to right, #fdfefe 0%, #fdfefe 50%, ${ticketBg} 50%, ${ticketBg} 100%)` }">
        <div class="panel">
          <div class="swiper">
            <swiper ref="vSwiper" :options="stepOptions">
              <swiper-slide v-for="item in ticketList" :key="item.key">
                <div class="step-swiper-container">
                  <div class="wrapper">
                    <p class="title">{{ item.title }}</p>
                    <p class="text">{{ item.content }}</p>
                    <div class="image"><img :src="require(`@/assets/images/product-three-${item.key}.png`)" /></div>
                  </div>
                </div>
              </swiper-slide>
              <div class="step-pagination" slot="pagination"></div>
            </swiper>
          </div>
          <div class="bg-radius" :style="{ background: ticketBgRadius }"></div>
        </div>
      </div>
      <div class="bg-con-four">
        <div class="panel">
          <div class="swiper">
            <swiper ref="mySwiper" :options="swiperOptions">
              <swiper-slide v-for="item in stepList" :key="item.key">
                <p class="title">简单三步 记账报税</p>
                <p class="text">{{ item.content }}</p>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
          <div class="image">
            <img src="@/assets/images/product-four-0.png" />
          </div>
        </div>
      </div>
      <div class="bg-con-five">
        <div class="panel">
          <div class="title">产品价值</div>
          <div class="card">
            <img src="@/assets/images/product-five-1.png" />
            <div class="content">
              <div class="content-box">
                <div class="card-title">零基础 三步完成记账报税</div>
                <div class="card-content">1/ 确认票据：确认系统自动获取的票据及手工上传的单据 <br />2/ 算税申报：以票算税，全税种申报 <br />3/ 一键缴款：一键点击扣款，扣款成功获取完税证明</div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="content">
              <div class="content-box">
                <div class="card-title">低投入 一月仅需要一杯咖啡的价格</div>
                <div class="card-content">先试用后购买，零试错成本;系统错误造成的损失，全额赔付;<br />按需购买，投入有保证;</div>
              </div>
            </div>
            <img src="@/assets/images/product-five-2.png" />
          </div>
          <div class="card">
            <img src="@/assets/images/product-five-3.png" />
            <div class="content">
              <div class="content-box">
                <div class="card-title">更规范 AI智能记账，高效、准确、合规</div>
                <div class="card-content">拍照、扫一扫智能记账；海量数据沉淀，科目账目准确清晰；风<br />控系统+专家双重把控</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-con-six">
        <div class="panel">
          <div class="title">产品优势</div>
          <div class="table">
            <img src="@/assets/images/product-six-0.png" />
          </div>
          <div class="advance">
            <div class="card" v-for="item in advance" :key="item.key">
              <svg-icon class="icon" :icon-class="`product-six-${item.key}`"></svg-icon>
              <p class="advance-title">{{ item.title }}</p>
              <p class="advance-content">{{ item.content }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="m-container" v-else>
      <Jumbotron url="product-m-banner" />
      <div class="m-product-desc">
        <p class="m-title">十角兽，让记账报税更简单</p>
        <p class="m-content">
          企业记账报税一定要找代理记账吗？ <br />十角兽无须专业知识，每月只需简单三步， <br />
          轻松搞定记账报税； <br />一杯咖啡的价格，低成本高收获。
        </p>
        <img src="@/assets/images/product-m-qrcode.png" />
      </div>
      <div class="m-product-service">
        <p class="m-title">产品服务范围</p>
        <div class="m-service-list">
          <div v-for="item in service" :key="item.key" class="m-service-card">
            <svg-icon class="m-icon" :icon-class="`product-two-${item.key}`"></svg-icon>
            <span class="m-text">{{ item.title | textFilter }}</span>
          </div>
        </div>
      </div>
      <div class="m-product-ticket">
        <swiper :options="mStepOptions">
          <swiper-slide v-for="item in ticketList" :key="item">
            <div class="m-swiper-container">
              <div class="wrapper">
                <p class="m-title">自动化发票获取</p>
                <p class="m-content">月初定时任务自动化获取发票数据，通过AI技术自动生成<br />记账凭证。</p>
                <img src="@/assets/images/product-m-step.png" />
              </div>
            </div>
          </swiper-slide>
          <div class="m-step-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <div class="m-product-tax">
        <swiper :options="mStepOptions">
          <swiper-slide v-for="item in stepList" :key="item.key">
            <div class="m-swiper-container">
              <div class="wrapper">
                <p class="m-title-white">简单三步 记账报税</p>
                <p class="m-sub-title">{{ item.key + ' ' + item.title }}</p>
                <p class="m-text">{{ item.content }}</p>
                <img src="@/assets/images/product-m-tax.png" />
              </div>
            </div>
          </swiper-slide>
          <div class="m-step-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <div class="m-product-value">
        <p class="m-title">产品价值</p>
        <p class="m-sub-title">零基础&#12288;三步完成记账报税</p>
        <p class="m-text tx-l">
          1、 确认票据：确认系统自动获取的票据及手工上传的单据<br />
          2、算税申报：以票算税，全税种申报<br />
          3、一键缴款：一键点击扣款，扣款成功获取完税证明
        </p>
        <img src="@/assets/images/product-m-val1.png" />
        <p class="m-sub-title">低投入&#12288;一月仅需要一杯咖啡的价格</p>
        <p class="m-text">先试用后购买，零试错成本；系统错误造成的损失，全额赔付； 按需购买，投入有保证；</p>
        <img src="@/assets/images/product-m-val2.png" />
        <p class="m-sub-title">更规范&#12288;AI智能记账，高效、准确、合规</p>
        <p class="m-text">拍照、扫一扫智能记账；海量数据沉淀，科目账目准确清晰； 风控系统+专家双重把控</p>
        <img src="@/assets/images/product-m-val3.png" />
      </div>
      <div class="m-product-advance">
        <p class="m-title">产品优势</p>
        <img src="@/assets/images/product-six-0.png" />
        <div class="m-advance-list">
          <div class="m-advance-card" v-for="item in advance" :key="item.key">
            <svg-icon class="m-icon" :icon-class="`product-six-${item.key}`"></svg-icon>
            <p class="m-advance-title">{{ item.title }}</p>
            <p class="m-advance-text">{{ item.content }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import checkIsMobile from '@/mixin/checkIsMobile'
import { Jumbotron } from '@/base'
import Navgation from '@/layouts/components/Navgation'

export default {
  name: 'Product',
  mixins: [checkIsMobile],
  filters: {
    textFilter(char) {
      if (char.length < 6) {
        const abs = 6 - char.length
        for (let i = 0; i < abs; i++) {
          char += '　'
        }
      }
      return char
    },
  },
  components: { Jumbotron,Navgation },
  props: {},
  data() {
    return {
      autoplayTimer: null,
      service: [
        { key: '1', title: '账套初始化' },
        { key: '2', title: '票据管理' },
        { key: '3', title: '薪酬表管理' },
        { key: '4', title: '银行流水管理' },
        { key: '5', title: '电子凭证管理' },
        { key: '6', title: '财务报表管理' },
        { key: '7', title: '按期申报纳税' },
        { key: '8', title: '系统咨询支持' },
        { key: '9', title: '汇算清缴申报' },
        { key: '10', title: '工商年检填报' },
      ],
      ticketList: [
        { key: '0', title: '自动化发票获取', content: '月初定时任务自动化获取发票数据，通过AI技术自动生成记账凭证。' },
        { key: '1', title: '一键补充单据', content: '扫码、拍照智能化单据补充，弥补自动化获取功能；单据补充成功即生成记账凭证，满足算税基本能力；' },
        { key: '2', title: '一键申报', content: '一键点击完成全税种申报，申报完成即通知' },
      ],
      advance: [
        { key: '1', title: '服务稳定', content: '　系统架构使用微服务架构，容器化部署，服务可靠性高，系统稳定性高，并发能力强' },
        { key: '2', title: '智能服务', content: '　系统使用OCR识别技术，智能推荐算法，企业发票等数据自动生成财务数据。' },
        { key: '3', title: '数据安全 ', content: '　敏感数据采用加密技术存储数据备份使用多云数据备份策略，保障企业数据安全' },
        { key: '4', title: '使用简单 ', content: '　saas系统升级方便、高效，功能迭代能力强。' },
      ],
      ticketBg: '#e9ebff',
      ticketBgRadius: '',
      stepList: [
        {
          key: '01',
          title: '记账',
          content: '财税专家坐阵，利用财税专业知识结合小微企业实际账务处理流程，输出企业智能化财税处理方案，月初单据自动化获取、薪酬自动化复制、银行流水自动化上传，数据确认无问题即完成企业账务处理。',
        },
        {
          key: '02',
          title: '报税',
          content: '支持企业全税种申报，数据确认无问题，每月5号系统自动执行算税、申报；申报结果公众号或专属客服及时通知，让企业老板更加省心、放心。',
        },
        {
          key: '03',
          title: '缴款',
          content: '有税款的企业一键缴款，支持三方协议扣款及二维码扫码扣款；扣款成功获取企业完税证明，企业老板无需登录税局查看结果。',
        },
      ],
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          type: 'custom',
          renderCustom: (swiper, current) => {
            const html = `<div class="swiper-item-list">
            <span class="swiper-item ${current === 1 && 'active'}">01<br><span class="name">记账</span></span>
            <span class="swiper-item ${current === 2 && 'active'}">02<br><span class="name">报税</span></span>
            <span class="swiper-item ${current === 3 && 'active'}">03<br><span class="name">缴款</span></span>
            </div>`
            setTimeout(() => {
              this.setSwiperHover()
            }, 100)
            return html
          },
        },
      },
      stepOptions: {
        autoplay: true,
        direction: 'vertical',
        pagination: {
          el: '.step-pagination',
          type: 'custom',
          renderCustom: (swiper, current) => {
            this.ticketBg = ['', '#e9ebff', '#DDEEFF', '#DDF5FE'][current]
            this.ticketBgRadius = [
              '',
              'linear-gradient(270deg, rgba(222, 226, 255, 0) 0%, #dee2ff 100%)',
              'linear-gradient(270deg, rgba(203, 227, 254, 0) 0%, #CBE3FE 100%)',
              'linear-gradient(270deg, rgba(202, 233, 252, 0) 0%, #CAE9FC 100%)',
            ][current]
            const html = `<div class="step-item-list">
            <span class="step-item ${current === 1 && 'active'}"></span>
            <span class="step-item ${current === 2 && 'active'}"></span>
            <span class="step-item ${current === 3 && 'active'}"></span>
            </div>`
            if (!this.autoplayTimer) {
              this.autoplayTimer = setTimeout(() => {
                this.handleSwiperChange()
                this.autoplayTimer = null
              }, 100)
            }
            return html
          },
        },
      },
      mStepOptions: {
        loop: true,
        autoplay: true,
        spaceBetween: 30,
        centeredSlides: true,
        pagination: {
          el: '.m-step-pagination',
          type: 'custom',
          renderCustom: (swiper, current) => {
            const html = `<div class="m-step-list">
            <span class="step-item ${current === 1 && 'active'}"></span>
            <span class="step-item ${current === 2 && 'active'}"></span>
            <span class="step-item ${current === 3 && 'active'}"></span>
            </div>`
            return html
          },
        },
      },
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
  },
  watch: {},
  mounted() {},
  created() {},
  methods: {
    handleSwiperChange() {
      const swiperItem = document.querySelectorAll('.step-item')
      for (let i = 0; i < swiperItem.length; i++) {
        swiperItem[i].onclick = () => {
          this.$refs.vSwiper.$swiper.slideTo(i, 200)
          setTimeout(() => {
            this.$refs.vSwiper.$swiper.autoplay.start()
          }, 400)
        }
      }
    },
    setSwiperHover() {
      const swiperItem = document.querySelectorAll('.swiper-item')
      for (let i = 0; i < swiperItem.length; i++) {
        swiperItem[i].onmouseover = () => {
          this.swiper.slideTo(i, 200)
        }
      }
    },
  },
}
</script>

<style scoped lang="less">
@media (min-width: 768px) {
  .container {
    margin-top: @navHeight;
    .panel {
      width: @panelWidth;
      height: 100%;
      margin: 0 auto;
      display: flex;
      position: relative;
    }
    .bg-con-one {
      height: 396px;
      background: linear-gradient(270deg, #f0f7ff 0%, #f9fcff 100%);
      .left {
        width: 100%;
        height: 100%;
        .title {
          font-size: 28px;
          font-family: AlibabaPuHuiTiM;
          color: #333333;
          line-height: 38px;
          position: absolute;
          top: 77px;
        }
        .desc {
          width: 456px;
          font-size: 16px;
          font-family: AlibabaPuHuiTiR;
          color: #333333;
          line-height: 22px;
          position: absolute;
          top: 128px;
        }
        img {
          width: 84px;
          height: 84px;
          position: absolute;
          top: 240px;
        }
      }
      .right {
        position: absolute;
        right: 0;
        bottom: 0;
        img {
          width: auto;
          height: 368px;
        }
      }
    }
    .bg-con-two {
      height: 510px;
      background: #ffffff;
      text-align: center;
      .panel {
        flex-direction: column;
      }
      .title {
        width: 100%;
        margin-top: 90px;
        font-family: AlibabaPuHuiTiM;
        font-size: 28px;
        color: #333333;
        line-height: 38px;
      }
      .service {
        width: 954px;
        display: flex;
        flex-wrap: wrap;
        padding-left: 123px;
        .card {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 0 0 25%;
          margin-top: 48px;
          cursor: pointer;
          .icon {
            width: 48px;
            height: 48px;
          }
          .text {
            width: 123px;
            height: 27px;
            font-size: 20px;
            font-family: AlibabaPuHuiTiR;
            color: #233b55;
            line-height: 27px;
            padding-left: 12px;
            text-align: left;
          }
          :hover {
            color: #2761ff;
          }
        }
      }
    }
    .bg-con-three {
      height: 710px;
      position: relative;
      overflow: hidden;
      .swiper {
        width: 1200px;
        height: 100%;
        .title {
          font-size: 32px;
          font-family: AlibabaPuHuiTiM;
          color: #333333;
          line-height: 44px;
        }
        .text {
          width: 361px;
          margin-top: 9px;
          font-size: 16px;
          font-family: AlibabaPuHuiTiR;
          color: #777777;
          line-height: 22px;
        }
      }
      .image {
        position: absolute;
        left: 55%;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        img {
          width: 455px;
          height: 454px;
        }
      }
      .bg-radius {
        width: 918px;
        height: 280px;
        background: linear-gradient(270deg, rgba(222, 226, 255, 0) 0%, #dee2ff 100%);
        position: absolute;
        top: 50%;
        left: 55%;
        transform: translateY(-50%);
        border-radius: 140px 0 0 140px;
      }
    }
    .bg-con-four {
      height: 710px;
      background: #3860f4;
      overflow: hidden;
      .swiper {
        width: 448px;
        height: 100%;
        .title {
          margin-top: 188px;
          font-size: 28px;
          font-family: AlibabaPuHuiTiM;
          color: #ffffff;
          line-height: 38px;
        }
        .text {
          margin-top: 28px;
          font-size: 14px;
          font-family: AlibabaPuHuiTiR;
          color: rgba(255, 255, 255, 0.8);
          line-height: 20px;
        }
      }
      .image {
        position: absolute;
        bottom: 0;
        left: 55%;
        img {
          width: 1124px;
          height: 630px;
        }
      }
    }
    .bg-con-five {
      height: 1413px;
      background: #ffffff;
      .panel {
        flex-direction: column;
        text-align: center;
        .title {
          margin-top: 90px;
          margin-bottom: 91px;
          font-size: 32px;
          font-family: AlibabaPuHuiTiM;
          color: #333333;
          line-height: 44px;
        }
        .content {
          display: flex;
          align-items: center;
        }
        .card {
          display: flex;
          justify-content: space-between;
          text-align: left;
          width: 1005px;
          margin: 0 auto;
          margin-bottom: 84px;
          .card-title {
            font-size: 24px;
            font-family: AlibabaPuHuiTiM;
            color: #333333;
            line-height: 33px;
            margin-bottom: 16px;
          }
          .card-content {
            font-size: 16px;
            font-family: AlibabaPuHuiTiR;
            color: #333333;
            line-height: 32px;
          }
          img {
            width: 444px;
            height: 276px;
          }
        }
      }
    }
    .bg-con-six {
      height: 724px;
      background: #f9fcff;
      .panel {
        flex-direction: column;
        text-align: center;
      }
      .table {
        img {
          width: 1104px;
          height: 198px;
        }
      }
      .title {
        font-size: 28px;
        font-family: AlibabaPuHuiTiM;
        color: #333333;
        line-height: 38px;
        margin: 91px 0 33px 0;
      }
      .advance {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: 91px;
        width: 1116px;
        .card {
          .icon {
            width: 46px;
            height: 46px;
          }
          .advance-title {
            font-size: 24px;
            font-family: AlibabaPuHuiTiM;
            color: #333333;
            line-height: 33px;
            margin: 19px 0 16px 0;
          }
          .advance-content {
            width: 210px;
            font-size: 14px;
            font-family: AlibabaPuHuiTiR;
            color: #777777;
            line-height: 20px;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .m-container {
    text-align: center;
    .m-title {
      font-size: 36px;
      color: #000000;
      line-height: 50px;
      font-weight: 600;
      padding: 44px 0 30px 0;
    }
    .m-content {
      font-size: 20px;
      font-weight: 400;
      color: #717a85;
      line-height: 36px;
    }
    .m-product-desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      background-image: url('~@/assets/images/product-m-bg.png');
      background-size: 750px 680px;
      width: 750px;
      height: 680px;
      img {
        width: 234px;
        height: 234px;
        position: absolute;
        bottom: 103px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .m-product-service {
      height: 690px;
      .m-service-list {
        padding: 0 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .m-service-card {
          width: 325px;
          padding: 24px 0;
          margin-bottom: 20px;
          background: linear-gradient(180deg, #f3f6f8 0%, #ffffff 100%);
          box-shadow: 0px 8px 20px 0px rgba(39, 51, 71, 0.06);
          border: 2px solid #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          .m-icon {
            width: 40px;
            height: 40px;
          }
          .m-text {
            font-size: 20px;
            color: #000000;
            line-height: 27px;
            padding-left: 8px;
          }
        }
      }
    }
    .m-product-ticket {
      background: #fafbfe;
      width: 750px;
      .m-swiper-container {
        img {
          width: 500px;
          height: 500px;
        }
      }
      /deep/ .m-step-list {
        display: flex;
        justify-content: center;
        padding-bottom: 54px;
        .step-item {
          width: 52px;
          height: 3px;
          background: #2761ff;
          opacity: 0.31;
        }
        .step-item:not(:first-child) {
          margin-left: 12px;
        }
        .active {
          opacity: 1;
        }
      }
    }
    .m-product-tax {
      background: #3860f4;
      width: 750px;
      .m-swiper-container {
        .m-title-white {
          font-size: 36px;
          color: #ffffff;
          line-height: 50px;
          padding: 40px 0 30px 0;
        }
        .m-sub-title {
          font-size: 24px;
          color: #ffffff;
          line-height: 33px;
          padding-bottom: 4px;
        }
        .m-text {
          font-size: 20px;
          color: rgba(255, 255, 255, 0.7);
          line-height: 36px;
          padding: 0 40px;
          padding-bottom: 48px;
        }
        img {
          width: 483px;
          height: 583px;
          float: right;
        }
      }
      /deep/ .m-step-list {
        display: flex;
        justify-content: center;
        padding-bottom: 54px;
        .step-item {
          width: 52px;
          height: 3px;
          background: #ffffff;
          opacity: 0.31;
        }
        .step-item:not(:first-child) {
          margin-left: 12px;
        }
        .active {
          opacity: 1;
        }
      }
    }
    .m-product-value {
      .m-title {
        font-size: 36px;
        color: #000000;
        line-height: 50px;
        padding: 43px 0 48px 0;
      }
      .m-sub-title {
        font-size: 24px;
        color: #333333;
        line-height: 33px;
        padding-bottom: 20px;
        font-weight: 500;
      }
      .m-text {
        font-size: 20px;
        padding: 0 110px;
        color: #717a85;
        line-height: 36px;
      }
      .tx-l {
        text-align: left;
      }
      img {
        padding: 55px 0 68px 0;
        width: 444px;
        height: 276px;
      }
    }
    .m-product-advance {
      .m-advance-list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 92px;
        .m-advance-card {
          width: 50%;
          margin-top: 66px;
          .m-icon {
            width: 46px;
            height: 46px;
            padding-bottom: 19px;
          }
          .m-advance-title {
            font-size: 24px;
            color: #333333;
            line-height: 33px;
            padding-bottom: 14px;
          }
          .m-advance-text {
            font-size: 16px;
            color: #777777;
            line-height: 22px;
            padding: 0 60px;
          }
        }
      }
      img {
        width: 670px;
      }
    }
  }
}
</style>

<style lang="less" scoped>
@media (min-width: 768px) {
  .swiper-container {
    height: 100%;
  }
  .bg-con-four {
    .swiper-pagination-custom {
      bottom: 60px;
    }
  }
  /deep/ .swiper-item-list {
    height: 100px;
    display: flex;
    justify-content: space-between;
    .swiper-item {
      cursor: pointer;
      .name {
        font-size: 20px;
        font-family: AlibabaPuHuiTiM;
        line-height: 27px;
      }
      width: 57px;
      height: 65px;
      font-size: 48px;
      font-family: AlibabaPuHuiTiB;
      color: rgba(255, 255, 255, 0.48);
      line-height: 65px;
    }
    .active {
      color: #ffffff;
    }
  }
  .bg-con-three {
    .swiper-pagination-custom {
      bottom: unset;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
    .step-swiper-container {
      display: flex;
      align-items: center;
      height: 100%;
      .wrapper {
        padding-left: 32px;
      }
    }
  }

  /deep/ .step-item-list {
    display: flex;
    flex-direction: column;
    width: 10px;
    .step-item {
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background: rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
    .step-item:not(:first-child) {
      margin-top: 20px;
    }
    .active {
      background: #1a7aff;
    }
  }
}
</style>

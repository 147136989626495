<template>
  <div class="home-container">
    <!-- banner -->
    <Banner1 />

    <!-- 产品 -->
    <Product />

  </div>
</template>

<script>
import * as componentList from '@/base'
export default {
  name: 'Home',
  components: {
    ...componentList,
  },
}
</script>
<style lang="less">
.el-popper{
  font-size: 14px;
  .el-cascader-node__label{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #102A55;
  }
  .in-active-path{
    .el-cascader-node__label{
      color: #2871FF;
    }
    .el-icon-arrow-right{
      color: #2871FF;
    }
  }
  .is-active{
    .el-icon-check{
      color: #2871FF;
    }
  }
}
</style>
